<template>
  <div :class="containerClass">
    <ul id="menu-social-media-menu-1" class="menu">
      <li class=""><a target="_blank" rel="noopener" href="https://www.instagram.com/radiokanalk/"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
      <li class=""><a target="_blank" rel="noopener" href="https://open.spotify.com/user/radiokanalk"><i class="fab fa-spotify" aria-hidden="true"></i></a></li>
      <li class=""><a target="_blank" rel="noopener" href="https://www.youtube.com/user/KanalKRadio"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
      <li class=""><a target="_blank" rel="noopener" href="https://www.facebook.com/radiokanalk/"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
      <li class=""><a target="_blank" rel="noopener" href="mailto:info@kanalk.ch"><i class="fa fa-envelope" aria-hidden="true"></i></a></li>
      <li v-if="newsletter" class=""><router-link to="/newsletter" @click.prevent="this.$store.dispatch('closeMenuBarFull')">Newsletter</router-link></li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuSocials",
  props: {
    containerClass: String,
    newsletter: Boolean
  }
}
</script>

<style scoped>
.menu-social > ul {
  display: flex;
}
.menu-social > ul > li > a {
  margin-right: 1rem;
  font-size: 1rem;
}
header .menu-social > ul > li > a {
  font-size: 1.2rem;
  margin-right: 1.2rem;
  color: var(--color-black);
}
header .menu-social > ul > li:last-of-type > a {
  margin-right: 0;
}
header .menu-social > ul > li > a:hover {
  color: var(--color-random2);
}
@media (max-width: 1300px) {
  header .menu-social.header > ul > li > a {
    font-size: 1.2rem;
    margin-right: 0.5rem;;
  }
}
@media (max-width: 1090px) {
  header .menu-social.header > ul > li > a {
display: none;
  }
}
@media (max-width: 992px) {
  #menubar .menu-social > ul {
    width: 100%;
    justify-content: space-between;
  }
  #menubar .menu-social > ul > li {
    /*flex: 1;*/
    /*text-align: center;*/
  }
  #menubar .menu-social > ul > li > a {
    font-size: 2rem;
  }
  footer .menu-social > ul > li > a {
    font-size: 1.3rem;
  }
}
</style>