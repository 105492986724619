<template>
  <section id="newsletter" :class="sectionClass" class="page bg-random2" style="">
    <div class="section-inner text-section ">
      <div class="section-title">
        <div class="container-fluid container-center">
          <h2 class="color-random1">Newsletter</h2>
        </div>
      </div>
      <div class="section-content">
        <div class="container-fluid container-center">
          <div class="row">
            <div class="col-lg-6">
              <FormNewsletter/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormNewsletter from "@/components/FormNewsletter";
export default {
  name: "SectionNewsletter",
  components: {FormNewsletter},
  props: {
    sectionClass: String
  },
}
</script>

<style scoped>

</style>
