<template>
  <div :class="containerClass">
    <ul class="menu">
      <li v-for="(menuItem, m) in this.$store.getters.getMenuItemsPrimary" :key="m"
          v-bind:class="this.getSubMenuState(menuItem.slug) ? 'submenu-opened ' + menuItem.slug:menuItem.slug">
        <router-link v-bind:to="menuItem.link" v-html="menuItem.title"
                     @click.prevent="this.$store.dispatch('closeMenuBarFull')"></router-link>
        <div class="submenu-toggle">
          <span @click.prevent="this.toggleSubMenu(menuItem.slug)">
            <SvgSubmenuOpened container-class="opened"></SvgSubmenuOpened>
           <SvgSubmenuClosed container-class="closed"></SvgSubmenuClosed>
          </span>
        </div>
        <ul class="submenu">
          <li v-for="(subMenuItem, sm) in menuItem.nested" :key="sm">
            <router-link v-bind:to="subMenuItem.link" v-html="subMenuItem.title"
                         @click.prevent="this.$store.dispatch('closeMenuBarFull')"></router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import SvgSubmenuOpened from "@/components/svg/SvgSubmenuOpened";
import SvgSubmenuClosed from "@/components/svg/SvgSubmenuClosed";

export default {
  name: "MenuPrimary",
  components: {
    SvgSubmenuOpened, SvgSubmenuClosed
  },
  props: {
    containerClass: String
  },
  computed: {},
  methods: {
    toggleSubMenu(subMenu) {
      this.$store.dispatch('toggleSubMenu', subMenu);
    },
    getSubMenuState(subMenu) {
      switch (subMenu) {
        case 'ausbildung':
          return this.$store.state.menu.subMenuAusbildung;
        case 'kommunity':
          return this.$store.state.menu.subMenuKommunity;
        case 'musik':
          return this.$store.state.menu.subMenuMusik;
        case 'sendungen':
          return this.$store.state.menu.subMenuSendungen;
        case 'podcasts':
          return this.$store.state.menu.subMenuPodcasts;
        default:
      }
    }
  }
}
</script>

<style scoped>
.menu-primary {
  margin-top: calc(var(--header-height) + var(--bottom-margin));
}

.menu-primary > ul > li {
  line-height: initial !important;
  background-color: var(--color-selected);
  margin-bottom: 0.75rem;
  transition: all ease var(--transition-time2);
  border-top: var(--border-width-thin) var(--color-black) solid;
  border-bottom: var(--border-width-thin) var(--color-black) solid;
  padding-top: 0.3rem;
}

.menu-primary > ul > li > a {
  transition: all ease var(--transition-time2);
  text-decoration: none;
  margin-bottom: 0.2rem;
  margin-left: var(--outer-sides-margin);
  margin-right: var(--outer-sides-margin);
}

.menu-primary > ul > li > .submenu-toggle {
  display: flex;
  float: right;
  margin-right: var(--outer-sides-margin);
  height: 100%;
  font-size: 35px;
  position: relative;
  width: 25px;
  transform: translateY(-4px);
}

.menu-primary > ul > li > .submenu-toggle > span {
  text-decoration: none;
  transform: translateY(-3px);
  cursor: pointer;
}

.menu-primary > ul > li > .submenu-toggle > span > div {
  width: 30px;
  position: absolute;
}

.menu-primary > ul > li.submenu-opened > .submenu-toggle > span > div.closed {
  display: none;
}

.menu-primary > ul > li > ul.submenu {
  transform: scaleY(0);
  max-height: 0;
  transition: all ease var(--transition-time1);
  transform-origin: top;
}

.menu-primary > ul > li.submenu-opened > ul.submenu {
  transform: scaleY(1);
  max-height: 300px;
}

.menu-primary > ul > li > ul.submenu > li {
  margin-bottom: 0;
  background-color: var(--color-blue);
  transition: all ease var(--transition-time2);
  border-top: var(--border-width-thin) var(--color-black) solid;
  margin-top: 0.1rem;
  margin-bottom: 0.2rem;
}

.menu-primary > ul > li > ul.submenu > li > a {
  margin: 0 var(--outer-sides-margin);
  transition: all ease var(--transition-time2);
  font-size: 0.7rem;
  line-height: 1rem;
  text-decoration: none;
  color: var(--color-black);
}

.menu-primary > ul > li > ul.submenu > li > a:hover {
  text-decoration: underline;
}

@media (max-width: 992px) {
  .menu-primary > ul > li > ul.submenu > li > a {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
}
</style>
