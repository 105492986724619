import axios from "axios";

let pbApiUrlBase = 'https://api.kanalk.ch/stream/';

/**
 * Get Stream URL
 * @returns {Promise<AxiosResponse<any>>}
 */
export function streamFetchStreamUrl() {
    return axios
        .get(pbApiUrlBase + '?q=defaultQC')
        .then(res => {
            if (typeof res.data.streamUrl !== "undefined") {
                return res.data.streamUrl
            }
            return false;
        });
}
