<template>
    <div class="content">
      <ErrorPageNotFound text="Diese Seite existiert nicht" v-if="error404" ></ErrorPageNotFound>
      <div class="container-fluid container-inner container-max">
        <Transition name="title">
          <h2 v-if="pageTitle" v-html="pageTitle"/>
        </Transition>
        <Transition name="content">
          <div class="text" v-if="pageContent" v-html="pageContent"/>
        </Transition>
        <Transition name="content">
         <FormNewsletter class="mt-1" v-if="showNewsletterForm && pageContent"/>
        </Transition>
        <Transition name="content">
          <FormMember class="mt-2" v-if="showMemberForm && pageContent"/>
        </Transition>
        <Transition name="content">
          <SettingCookies class="mt-2" v-if="showCookieSettings && pageContent"/>
        </Transition>
      </div>
    </div>
</template>

<script>
import {wpFetchPageData} from "@/api/wordpress";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import FormNewsletter from "@/components/FormNewsletter";
import FormMember from "@/components/FormMember";
import SettingCookies from "@/components/SettingCookies";
// import {useProgress} from "@marcoschulte/vue3-progress";


export default {
  name: "PageView",
  components: {SettingCookies, FormMember, FormNewsletter, ErrorPageNotFound},
  props: {
    showNewsletterForm: Boolean,
    showMemberForm: Boolean,
    showCookieSettings: Boolean,
  },
  data() {
    return {
      error404: false,
      pageTitle: null,
      pageContent: null,
    }
  },
  methods: {},
  computed: {},
  watch: {},
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if(result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      //Fill Data
      // this.wpPageData = result
      this.pageTitle = result.title;
      this.pageContent = result.content;
      this.$store.state.loadingProgressbar.finish()
    })
  },
  mounted() {}
}
</script>

<style scoped>

</style>
