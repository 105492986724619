<template>
  <header>
    <div class="header-fixed container-fluid container-outer can-inherit">
      <!-- Nav Toggle  -->
      <div class="menutoggle">
        <div class="hamburger" @click.prevent="this.$store.dispatch('toggleMenuBar')">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <!-- Logo -->
      <PartLogo></PartLogo>
      <!-- Player -->
      <PartPlayer :class="[this.$route.name == 'player' ? 'd-none':'']"></PartPlayer>

      <!-- Social Menu -->
      <MenuSocials container-class="menu-social header d-none d-lg-flex"></MenuSocials>

      <!-- Open Menu Layer -->
      <div id="menulayer" @click.prevent="this.$store.dispatch('closeMenuBarFull')"></div>
      <!-- Menu Bar -->
      <div id="menubar">
        <MenuPrimary container-class="menu-primary"></MenuPrimary>
        <div class="info-widget medium-small d-none d-lg-block mt-3">
          <MenuSocials newsletter container-class="menu-social mb-1"></MenuSocials>
          <WidgetContact container-class="text"></WidgetContact>
        </div>
        <div class="container-fluid container-outer d-block d-lg-none">
          <MenuSocials container-class="menu-social mt-3 mb-2"></MenuSocials>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import MenuPrimary from "@/components/MenuPrimary";
import MenuSocials from "@/components/MenuSocials";
import PartPlayer from "@/components/PartPlayer";
import PartLogo from "@/components/PartLogo";
import WidgetContact from "@/components/widgets/WidgetContact";

export default {
  name: "PartHeader",
  components: {
    PartPlayer, MenuPrimary, MenuSocials, PartLogo, WidgetContact
    // HelloWorld
  },
}
</script>

<style scoped>
header {

}

header .header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  border: var(--color-black) solid var(--border-width-thin);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 7;
  transition: background-color ease var(--transition-time1), border ease var(--transition-time1);
}

#content-wrapper.post-background header .header-fixed {
  background-color: transparent;
}

#content-wrapper.post-background.scrolled header .header-fixed {
  background-color: var(--color-white);
}

#content-wrapper.landing.player-display-full header .header-fixed,
#content-wrapper.player header .header-fixed {
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

#content-wrapper.landing.player-display-full.menu-bar-opened .header-fixed,
#content-wrapper.player.menu-bar-opened .header-fixed {
  transition: none;
  background-color: transparent;
}

header .menutoggle {
  display: flex;
  align-items: center;
  height: var(--header-height);
  z-index: 11;
}

header .menutoggle .hamburger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--hamburger-width);
  height: var(--hamburger-height);
  cursor: pointer;
  transition: all ease var(--transition-time1);

}

header .menutoggle .hamburger > div {
  width: 100%;
  height: var(--hamburger-slize-size);
  background-color: var(--color-black);
  display: block;
  position: absolute;
  transform-origin: left;
  transition: all ease var(--transition-time1);
}

header .menutoggle .hamburger > div:nth-child(1) {
  top: var(--hamburger-slize-size);
}

header .menutoggle .hamburger > div:nth-child(3) {
  bottom: var(--hamburger-slize-size);
}

.menu-bar-opened .menutoggle .hamburger > div:nth-child(1) {
  top: 0;
  transform: translateX(var(--hamburger-slize-size)) rotate(45deg);
}

.menu-bar-opened .menutoggle .hamburger > div:nth-child(2) {
  transform: translatex(-500%);
  opacity: 0;
}

.menu-bar-opened .menutoggle .hamburger > div:nth-child(3) {
  bottom: 0;
  transform: translateX(var(--hamburger-slize-size)) rotate(-45deg);
}

#menubar {
  height: 100vh;
  width: 30vw;
  min-width: 450px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  z-index: 10;
  transition: all ease var(--transition-time1);
  transform: translateX(-100%);
  border: var(--border-width-thin) var(--color-black) solid;
  /*border-left: none;*/
  overflow: auto;
}

.menu-bar-opened #menubar {
  transform: translateX(0);
}

#menubar .info-widget {
  position: initial;
  margin-left: var(--outer-sides-margin);
  margin-bottom: var(--bottom-margin);
  bottom: var(--bottom-margin);
  left: var(--outer-sides-margin);
}

#menulayer {
  background-color: var(--color-black-op75);
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 10;
  opacity: 0;
  transition: opacity ease var(--transition-time1);
}

.menu-bar-opened #menulayer {
  width: 100vw;
  height: 100vh;
  opacity: 1;
}
@media (max-width: 992px), (max-height: 600px) {

}

@media (max-width: 992px) {
  #content-wrapper.post-background header .header-fixed {
    background-color: var(--color-white);
  }
  #content-wrapper.landing.player-display-full header > .header-fixed,
  #content-wrapper.player header > .header-fixed {
    background-color: transparent;
    border-color: transparent;
  }

  header .menutoggle {
    order: 2;
  }
  .menu-bar-opened header .menutoggle .hamburger > div:nth-child(2) {
    transform: translatex(150%);
  }
  #menubar {
    width: 100vw;
    min-width: initial;
  }
}
</style>
