<template>
  <div id="player-landing" :class="[this.$store.state.player.playing ? 'playing':'paused']">
    <!-- Controls -->
    <div id="controls" class="controls">
      <div class="button-play-pause" @click.prevent="this.$store.dispatch('togglePlayer',getCurrentPlayerAttrs)">
        <SvgPlayerButtonPause container-class="played"></SvgPlayerButtonPause>
        <SvgPlayerButtonPlay container-class="paused"></SvgPlayerButtonPlay>
      </div>
    </div>
    <!-- Songanzeige -->
    <div id="songanzeige" class="songanzeige">
      <div id="">
        <div class="meta"
             v-if="this.$store.getters.getPlayerMode === 'stream' && (this.$store.getters.getRadiotxtSongCurrent || this.$store.getters.getRadiotxtProgramCurrentTitle)">
          <div>Jetzt Live:</div>
          <div class="current">
            <div class="program-current" id="program-current">
              <router-link v-bind:to="'/sendung/' + this.$store.getters.getRadiotxtProgramCurrentProgramId"
                           v-html="this.$store.getters.getRadiotxtProgramCurrentTitle"/>
            </div>
            <div class="song" id="song" v-if="this.$store.getters.getRadiotxtSongCurrent"
                 v-html="this.$store.getters.getRadiotxtSongCurrent"/>
          </div>
          <div class="program-next" id="program-next" v-if="this.$store.getters.getRadiotxtProgramNextStart">
            <span>Ab </span>
            <span v-html="this.$store.getters.getRadiotxtProgramNextStart"/>
            <span>:&nbsp;&nbsp;</span>
            <router-link v-bind:to="'/sendung/' + this.$store.getters.getRadiotxtProgramNextProgramId"
                         v-html="this.$store.getters.getRadiotxtProgramNextTitle"/>
          </div>
        </div>
        <div class="meta" v-else-if="this.$store.getters.getPlayerMode === 'podcast'">
          <div>Podcast:</div>
          <div class="podcasttitle">
            <router-link v-bind:to="this.$store.getters.getPodcastEpisodeRoutePath" v-html="getPodcastSendungTitle"/>

          </div>
          <div class="stream-link" id="program-next">
            <a href="#" @click.prevent="this.$store.dispatch('togglePlayer',getStreamPlayerAttrs)">Stream hören!</a>
          </div>
        </div>
      </div>
    </div>
    <BoxProgrammPlaylistLanding></BoxProgrammPlaylistLanding>
  </div>
</template>

<script>
import SvgPlayerButtonPlay from "@/components/svg/SvgPlayerButtonPlay";
import SvgPlayerButtonPause from "@/components/svg/SvgPlayerButtonPause";
import BoxProgrammPlaylistLanding from "@/components/BoxProgrammPlaylistLanding";

export default {
  name: "PartPlayerLanding",
  components: {
    BoxProgrammPlaylistLanding,
    SvgPlayerButtonPlay, SvgPlayerButtonPause
  },
  data() {
    return {
      metaPlaylist: null,
      // playlistStatus: '',
      pbProgram: null,
      // programStatus: ''
    }
  },
  computed: {
    getPodcastSendungTitle() {
      if (this.$store.getters.getPodcastSendungTitle) {
        return this.$store.getters.getPodcastSendungTitle + ' - ' + this.$store.getters.getPodcastTitle
      } else {
        return this.$store.getters.getPodcastTitle
      }
    },
    getCurrentPlayerAttrs() {
      return {
        'playerMode': this.$store.getters.getPlayerMode,
        'podcastAudioUrl': this.$store.getters.getPodcastAudioUrl,
        'podcastTitle': this.$store.getters.getPodcastTitle,
      };
    },
    getStreamPlayerAttrs() {
      return {
        'playerMode': 'stream',
        'podcastAudioUrl': this.$store.getters.getStreamAudioUrl,
        'podcastTitle': '',
      };
    }
  },
  methods: {}
}
</script>

<style scoped>
#player-landing {
  height: 95vh;
  position: relative;
  border-top: solid transparent var(--border-width-thin);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 33%;
  z-index: 8;
  border: solid var(--color-black) var(--border-width-thin);
  transition: opacity ease var(--transition-time1), z-index ease 0s, transform ease var(--transition-time2);
  background-color: var(--color-random1);
}

#content-wrapper.player-display-default #player-landing {
  transform: translate(-50%, -15vh);
}

.menu-bar-opened #player-landing {
  opacity: 1;
  /*transform: translate(-50%, -100%);*/
  /*margin-top: -80vh;*/
  z-index: 6;
  transition: z-index ease 0s;
}

#player-landing #controls {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-random1);
}

#player-landing #controls .button-play-pause {
  transition: height 200ms ease, width 200ms ease;
  width: calc(var(--player-width) * 2.4);
  height: calc(var(--player-height) * 2.4);
  cursor: pointer;
}

#player-landing #controls .button-play-pause > div {
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ease var(--transition-time1);
}

#player-landing #controls .button-play-pause > div.paused {
  transform: scale(1) translateX(13%);
}

#player-landing.paused #controls .button-play-pause > div.played,
#player-landing.playing #controls .button-play-pause > div.paused {
  transform: scale(0);
}

#player-landing #controls .button-play-pause svg .playbtn2, #player-landing #controls .button-play-pause svg .pausebtn3 {
  fill: var(--color-random1);
}

#player-landing #songanzeige {
  position: absolute;
  width: calc(100% + 2 * var(--border-width-thin));
  border: solid var(--color-black) var(--border-width-thin);
  overflow: hidden;
  border-color: transparent;
  background-color: transparent;
  transform: translate(-0%, 0);
  bottom: var(--outer-sides-margin);
}

#player-landing #songanzeige > div > .meta {
  padding: 0 var(--inline-sides-margin);
}

#player-landing #songanzeige > div > .meta > .current,
#player-landing #songanzeige > div > .meta > .podcasttitle {
  border: solid var(--color-black) var(--border-width-thin);
  background-color: var(--color-white);
  margin: 0 calc(var(--box-margin) * -1);
  padding: var(--border-width-bold) var(--box-margin);
  font-weight: 600;
  max-height: 126px;
  overflow-y: auto;
}

#player-landing #songanzeige > div > .meta > .program-next,
#player-landing #songanzeige > div > .meta > .stream-link {
  max-height: 60px;
  overflow-y: auto;
  margin: var(--border-width-bold) 0;
}

#player-landing #songanzeige > div > .meta .program-current a,
#player-landing #songanzeige > div > .meta .program-next a,
#player-landing #songanzeige > div > .meta .stream-link a,
#player-landing #songanzeige > div > .meta .podcasttitle a {
  color: var(--color-black);
  text-decoration: none;
}

#player-landing #songanzeige > div > .meta .program-current a:hover,
#player-landing #songanzeige > div > .meta .program-next a:hover,
#player-landing #songanzeige > div > .meta .stream-link a:hover,
#player-landing #songanzeige > div > .meta .podcasttitle a:hover {
  text-decoration: underline;
}

#player-landing #songanzeige > div > .meta div.current > .song {
  font-style: italic;
}


@media (max-width: 992px), (max-height: 600px) {
  #player-landing {
    height: calc(100vh - 330px);
    height: calc(100vh - 50px);
    margin-bottom: 10vh;
    width: calc(100% - var(--border-width-thin) * 1.6);
    width: 100%;
    top: calc(var(--header-height) - var(--border-width-thin));
    top: 0;
    z-index: 5;
    display: block;
  }

  #content-wrapper.scrolled #player-landing {
    transform: translate(-50%, calc(var(--header-height) * -1));
  }

  #player #songanzeige {
    width: 100vw;
    transform: translateY(100%);
    background-color: var(--color-random1);
    overflow: hidden;
    padding: 0;
  }

  #player #songanzeige .meta > .current {
    padding: 0rem var(--box-margin);
  }

  #player #controls {
    background-color: transparent;
    border-color: transparent;
  }

  #player-landing .box {
    display: none;
  }
}

@media (max-width: 575px) {
  #player-landing #songanzeige {
    bottom: var(--box-margin);
  }
}

@media (max-height: 600px) {
  #player-landing #controls {
    width: 100%;
    height: 50vh;
  }

  #player-landing #songanzeige > div > .meta > .current,
  #player-landing #songanzeige > div > .meta > .podcasttitle {
    max-height: 100px;
  }

  #player-landing #controls .button-play-pause {
    width: calc(var(--player-width) * 1.5);
    height: calc(var(--player-height) * 1.5);
  }

  #player-landing #songanzeige {
    bottom: var(--box-margin);
  }
}

</style>
