import axios from "axios";

let mailApiUrlBase = 'https://api.kanalk.ch/mail/';

/**
 * Send Mail via SMTP - Form Data needs to be Prepared beforehand
 * @param formData
 * @returns {Promise<AxiosResponse<any>>}
 */
export function mailMemberForm(formData) {
    return axios
        .post(mailApiUrlBase, formData, {
            headers: {'Content-Type': 'application/x-www-form-urlencoded',}
        })
        .then(function (res) {
            if (typeof res !== "undefined") {
                return res.data
            }
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });
}