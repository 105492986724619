<template>
  <div class="content page">
    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div class="text" v-if="pageContent">
          <form class="text " action="https://backend.kanalk.ch/wp-login.php" method="post">

            <input class="mb-1" type="text" placeholder="Benutzer*innenname oder E-Mail-Adresse" name="log" id="user_login">
            <input type="password" name="pwd" id="user_pass" class="mb-1" value=""  placeholder="Passwort" >
            <!--            <input id="datum" name="Datum" size="8" class="hasDatepicker">-->
            <button class="btn" type="submit">Anmelden</button>

          </form>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackendView",
  data() {
    return {
      pageTitle: null,
      pageContent: null,
    }
  },
  created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    setTimeout(() => {
      this.pageTitle = 'Wordpress Login';
      this.pageContent = true;
      this.$store.state.loadingProgressbar.finish()
    }, 1);
  },
}
</script>

<style scoped>

</style>
