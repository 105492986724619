<template>
  <div id="inline-player" :class="[getPodcastPlayingClass, isPodcastLoaded]">
    <div id="controls" class="controls">
      <div class="button-play-pause" @click.prevent="this.$store.dispatch('togglePlayer',playerAttrs)">
        <SvgPlayerButtonPause container-class="played"></SvgPlayerButtonPause>
        <SvgPlayerButtonPlay container-class="paused"></SvgPlayerButtonPlay>
      </div>
    </div>

    <div id="timeline" class="" @click="this.$store.dispatch('clickPlayerTimeline',$event)">
      <div id="playhead" ref="playhead" :style="{width: this.$store.getters.getPlayerAudioProgress + '%'}">
        <div class="needle" :style="{left: this.$store.getters.getPlayerAudioProgress + '%'}"/>
      </div>
    </div>
    <div id="time-controls">
      <div v-if="isPodcastLoaded" class="time current" v-html="this.$store.getters.getPlayerAudioProgressTime"/>
      <div v-else class="time current" v-html="this.getPodcastAudioProgressZero"/>
      <SvgPlayerButtonBackward15s container-class="time button-forward-backward button-backward" @click.prevent="this.$store.dispatch('setPlayerAudioBackward15s')"/>
      <SvgPlayerButtonForward15s container-class="time button-forward-backward button-forward" @click.prevent="this.$store.dispatch('setPlayerAudioForward15s')"/>
      <div class="time duration" v-html="this.getPodcastAudioDuration"/>
    </div>

  </div>
</template>

<script>
import SvgPlayerButtonPause from "@/components/svg/SvgPlayerButtonPause";
import SvgPlayerButtonPlay from "@/components/svg/SvgPlayerButtonPlay";
import SvgPlayerButtonBackward15s from "@/components/svg/SvgPlayerButtonBackward15s";
import SvgPlayerButtonForward15s from "@/components/svg/SvgPlayerButtonForward15s";
import {globalNumberToTime, globalAudioDuration} from "@/main";

export default {
  name: "PartPlayerPodcastInline",
  components: {SvgPlayerButtonPlay, SvgPlayerButtonPause, SvgPlayerButtonBackward15s, SvgPlayerButtonForward15s},
  props: {
    playerAttrs: Object,
  },
  data() {
    return {
      podcastAudioDuration: '0:00',
    }
  },
  computed: {
    getPodcastAudioDuration() {
      return this.podcastAudioDuration;
    },
    getPodcastAudioProgressZero() {
      return globalNumberToTime(0);
    },
    getPodcastPlayingClass() {
      return this.$store.getters.getPlayerPlaying && this.$store.getters.getPlayerMode == 'podcast' && this.$store.getters.getPlayerAudioSrc == this.playerAttrs.podcastAudioUrl ? 'playing' : 'paused'
    },
    isPodcastLoaded() {
      return this.$store.getters.getPlayerAudioSrc == this.playerAttrs.podcastAudioUrl ? 'loaded' : false
    }
  },
  methods: {

  },
  mounted() {
    globalAudioDuration(this.playerAttrs.podcastAudioUrl).then(duration => {
      this.podcastAudioDuration = globalNumberToTime(duration);
    });
  }
}
</script>

<style scoped>


#inline-player {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border: var(--color-black) solid var(--border-width-thin);
  padding: var(--inline-sides-margin);
  overflow: hidden;
}

#inline-player #controls {
  margin-bottom: var(--inline-sides-margin);
}

#inline-player #controls .button-play-pause {
  transition: height 200ms ease, width 200ms ease;
  cursor: pointer;
  width: var(--player-width);
  height: var(--player-height);
}

#inline-player #controls .button-play-pause > div {
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ease var(--transition-time1);
}


#inline-player #controls .button-play-pause > div.paused {
  transform: scale(1) translateX(13%);
}

#inline-player.paused #controls .button-play-pause > div.played,
#inline-player.playing #controls .button-play-pause > div.paused {
  transform: scale(0);
}

#inline-player #controls .button-play-pause svg .playbtn2, player #controls .button-play-pause svg .pausebtn3 {
  fill: var(--color-yellow);
}

#inline-player #timeline {
  width: 100%;
  background-color: var(--color-black);
  height: 25px;
  float: left;
  cursor: text;
  position: relative;
  display: flex;
  align-items: center;
  border: var(--color-black) solid var(--border-width-bold);
  transition: all ease var(--transition-time1);
  margin-bottom: var(--inline-sides-margin);

}

#inline-player.paused:not(.loaded) #timeline {
height: 0px;
border: 0;
  margin-bottom: 0;
}



#inline-player #timeline #playhead {
  display: inline-block;
  /*background-color: var(--color-black-op25);*/
  border-right: none;
  transition: background-color ease var(--transition-time1);
  height: 100%;
}

#inline-player #timeline #playhead .needle {
  height: 21px;
  width: var(--border-width-bold);
  /*background-color: var(--color-black);*/
  /*background-color: white;*/
  transform: translateX(-50%);
  transition: height ease var(--transition-time1);
  position: absolute;
}
#inline-player.paused:not(.loaded) #timeline #playhead .needle {
  height: 0;
}

#inline-player #time-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#inline-player #time-controls .time {
  font-size: 0.8rem;
  color: var(--color-black);
  text-shadow: 1px 1px 5px var(--color-black-op25);

}

#inline-player #time-controls .time.current,
#inline-player #time-controls .time.duration {
  min-width: 90px;
}

#inline-player #time-controls .time.duration,
#inline-player #time-controls .time.button-forward {
  text-align: right;
}

#inline-player #time-controls .time.button-forward-backward {
  cursor: pointer;
  flex-grow: 2;
  transition: all ease var(--transition-time1);
  transform: translateY(-2px);
}

#inline-player:not(.loaded) #time-controls .time.button-forward-backward {
  transform: translateY(50px);
  opacity: 0;
}

/*#inline-player #time-controls .time.button-forward svg {*/

/*}*/

/*#inline-player #time-controls .button-forward-backward svg {*/
/*  position: absolute;*/
/*  top: 0;*/
/*}*/
</style>
