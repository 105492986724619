<template>
  <audio id="playeraudio" type="audio/mp3" src="" preload="none" @ended="onPodcastEnded"></audio>
</template>

<script>
export default {
  name: "PartPlayerAudio",
  methods: {
    onPodcastEnded(){
      this.$store.dispatch("togglePlayerStream");
    }
  },
  mounted() {
    this.$store.dispatch('updatePlayerAudioProgress');
  }
}
</script>

<style scoped>

</style>