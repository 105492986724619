<template>
  <div class="content page">
    <ErrorPageNotFound></ErrorPageNotFound>
    </div>
</template>

<script>
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
export default {
  name: "ErrorView",
  components: {
    ErrorPageNotFound
  },
  setup() {  },
}
</script>

<style scoped>

</style>
