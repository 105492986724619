<template>
  <form @submit.prevent="submitMailchipForm">
    <input type="text" name="email" id="email" v-model="mailchimpEmail" placeholder="E-Mail"
           :class="[this.formError ? 'error':'']"/>
    <!--    <input type="hidden" name="u" value="ce6bf82f28530e53c1c95ddb1">
        <input type="hidden" name="id" value="cfc57a8879" >
        <input type="email" autocapitalize="off" autocorrect="off" name="MERGE0" id="MERGE0" placeholder="E-Mail" v-model="mailchimp.formData.MERGE0">-->
    <Transition name="info">
      <div class="medium-small mt--1 mb-1" v-if="formErrorFeedback" v-html="formErrorFeedback"/>
    </Transition>
    <Transition name="info">
      <div class="medium-small mt--1 mb-1" v-if="formSuccessFeedback" v-html="formSuccessFeedback"/>
    </Transition>
    <button class="btn" type="submit">Anmelden</button>
  </form>
</template>

<script>
import {mailchimpSubmitSubscriptionForm} from "@/api/mailchimp";
import {globalValidateEmail} from "@/main";

export default {
  name: "FormNewsletter",
  components: {},
  data() {
    return {
      mailchimpEmail: '',
      // 'g-recaptcha-response': '',
      formRecaptcha: '',
      formError: false,
      formErrorFeedback: '',
      formSuccessFeedback: ''
    }
  },
  computed: {
    getMailchimpEmail() {
      return this.mailchimpEmail;
    }
  },
  methods: {
    submitMailchipForm() {
      /*Validate Form Input*/
      if (this.mailchimpEmail == '') {
        this.formErrorFeedback = "Bitte E-Mail Adresse angeben";
        this.formError = true;
      } else if (!globalValidateEmail(this.mailchimpEmail)) {
        this.formErrorFeedback = "Bitte gültige E-Mail Adresse angeben";
        this.formError = true;
      } else {
        this.formErrorFeedback = '';
        this.formError = false;
        if (this.$progress.state().active) {
          this.$store.state.loadingProgressbar.finish()
        }
        this.$store.state.loadingProgressbar = this.$progress.start();
        mailchimpSubmitSubscriptionForm(this.mailchimpEmail).then(result => {
          //Neuanmeldung
          if (result.status == 'pending') {
            this.formSuccessFeedback = "Vielen Dank für deine Anmeldung. Bitte bestätige deine Email-Adresse in der Mail, die du bekommen hast. Danach erhälst du in Zukunft den Kanal K Newsletter.";
          }
          //Fehler
          if (result.status == 'archived' || result.status == 'error') {
            this.formSuccessFeedback = 'Oops, da ist leider etwas schiefgelaufen. Bitte wende dich an <a href="mailto:info@kanalk.ch?subject=Newsletter%20Anmeldung%20' + this.mailchimpEmail + '">info@kanalk.ch</a>';
          }
          //Bereits registriert
          if (result.status == 'existing' || result.status == 'subscribed') {
            this.formSuccessFeedback = "Diese Email-Adresse ist bereits registriert. Du erhälst weiterhin den Kanal K Newsletter.";
          }
          this.mailchimpEmail = '';
          this.$store.state.loadingProgressbar.finish()
        })
      }
    },
  },
  watch: {
    getMailchimpEmail() { //Hide Error Message, when input changes
      this.formError = false;
      this.formErrorFeedback = '';
    }
  },
}
</script>

<style scoped>

</style>
