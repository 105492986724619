<template>
  <div class="container-fluid container-inner container-max">
    <Transition name="title">
      <h2 v-if="pageTitle" v-html="pageTitle"/>
    </Transition>
    <Transition name="content">
      <div class="text" v-if="text && pageContent" v-html="text" />
      <div class="text" v-else-if="pageContent" v-html="pageContent"/>
    </Transition>
    <Transition name="content">
      <div v-if="!this.$store.state.player.playing && pageContent" class="text mt-1">
        <router-link to="/player" class="player-link" @click.prevent="this.$store.dispatch('togglePlayer',getCurrentPlayerAttrs)">
          Klicke hier, um Kanal K online zu hören
        </router-link>
      </div>
    </Transition>
    <Transition name="content">
      <div class="text mt-1" v-if="pageContent">
        <h3>Andere spannende Orte:</h3>
        <ul>
          <li>
            <router-link to="/newsletter">Newsletter</router-link>
          </li>
          <li>
            <router-link to="/empfang">Radio-Empfang</router-link>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>

export default {
  name: "ErrorPageNotFound",
  props: {
    text: String,
  },
  data() {
    return {
      pageTitle: null,
      pageContent: null,
    }
  },
  computed: {
    getCurrentPlayerAttrs() {
      return {
        'playerMode': this.$store.getters.getPlayerMode,
        'podcastAudioUrl': this.$store.getters.getPodcastAudioUrl,
        'podcastTitle': '',
      };
    },
  },
  created() {
    if(this.$progress.state().active) {
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    setTimeout(() => {
      this.pageTitle = 'Error';
      this.pageContent = 'Diese Seite existiert nicht.';
      this.$store.state.loadingProgressbar.finish()
    }, 1);
  },
}
</script>

<style scoped>
.player-link {}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>