<template>
  <div class="logo">
    <router-link to="/" @click.prevent="this.$store.dispatch('closeMenuBarFull')">
      <img class="d-none d-lg-block" :src="require('@/assets/img/NEW_Kanal_K_Logo_schwarz_mit_Claim.png')"/>
      <img class="d-block d-lg-none icon" :src="require('@/assets/img/NEW_Kanal_K_Logo_Web_RGB_schwarz_ICON.png')"/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "PartLogo",
  props: {
    containerClass: String,
    logoFile: String,
  }
}
</script>

<style scoped>
header .logo {
  position: absolute;
  left: 0;
  margin-left: var(--inner-sides-margin);
  z-index: 11;
}

header .logo img.icon {
  width: 90px;
}

header .logo img {
  width: 160px;
}

header .menu-social > ul > li > a:hover {
  color: var(--color-selected);
}

@media (max-width: 992px) {
  header .logo {
    position: relative;
    order: 1;
    margin-left: 0;
  }

}

</style>