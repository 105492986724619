<template>
  <div :class="containerClass">
    <div>
      <p>Kanal K<br>Rohrerstrasse 20<br>5000 Aarau</p>
    </div>
    <div>
      <p>
        Tel. <a href="tel:+41628349081">062 834 90 81</a>
        <br>
        Studio: <a href="tel:+41628349080">062 834 90 80</a>
      </p>
    </div>
    <div>
      <p>
        <a href="mailto:info@kanalk.ch">info@kanalk.ch</a><br>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "widgetContact",
  props: {
    containerClass: String
  },
}
</script>

<style scoped>

</style>