<template>
  <div class="content">
    <ErrorPageNotFound text="" v-if="error404"></ErrorPageNotFound>

    <Transition name="popup">
      <WidgetDisclaimer widget-id="kommunity-pronouns-disclaimer" :popup-name="this.popupName"
                        v-if="this.$store.state.popup.kommunityPronounsDisclaimer.display"/>
    </Transition>

    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div v-if="pageContent" class="mb-1" v-html="pageContent"/>
      </Transition>
      <div class="row gut-10px">
        <TransitionGroup name="tiles">
          <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" v-for="(person, p) in wpKommunity" :key="p">
            <div class="bg canvas can-threefour person" :ref="person.slug" @click="toggleHoverClass(person.slug)"
                 v-on:mouseleave="removeHoverClass(person.slug)"
                 v-bind:style="{ backgroundImage: 'url(' + person.image + ')' }" v-bind:data-src="person.image">
              <div class="hover-layer">
                <div class="person-title">{{ person.name }}</div>
                <div class="person-pronouns" @click="this.$store.commit('setPopupDisplay', {name:this.popupName,display:true})">{{ person.pronomen }}</div>
                <div v-if="this.kommunityCategory == 'team' || this.kommunityCategory.title == 'verwaltungsrat'"
                     class="person-description" v-html="person.funktion"/>
                <div v-if="this.kommunityCategory == 'team' || this.kommunityCategory == 'ausbildungsredaktion'"
                     class="person-contact"><a
                    v-bind:href="person.email"><i class="fa fa-envelope"></i></a></div>
                <div v-if="this.kommunityCategory == 'vorstand'" class="person-contact"><a
                    href="mailto:ig_vorstand@kanalk.ch"><i class="fa fa-envelope"></i></a></div>
                <div v-if="this.kommunityCategory == 'sendungsmachende'" class="person-sendung">
                  <template v-for="(sendung, s) in person.sendungen" :key="s">
                    <router-link v-bind:to="'/sendung/' + sendung.slug" class="sendung-link"
                                 v-html="sendung.title"/>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
      <Transition name="content">
        <div class="text mt-2" v-if="kommunityCategory == 'ausbildungsredaktion'">
          <router-link to="/kommunity/ehemalige-prakties">Ehemalige Praktikant*innen</router-link>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import {
  wpFetchPageData,
  wpFetchKommunityPostsByCategory
}
from "@/api/wordpress";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {globalImagePreload} from "@/main";
import WidgetDisclaimer from "@/components/PartPopup";

export default {
  name: "KommunityPeopleView",
  components: {
    WidgetDisclaimer,
    ErrorPageNotFound,
  },
  setup() {

  },
  data() {
    return {
      // wpPageData: null,
      error404: false,
      pageTitle: null,
      pageContent: null,
      wpKommunity: [],
      popupName: 'kommunityPronounsDisclaimer',
      kommunityCategory: null,
    }
  },
  methods: {
    setKommunityCategory() {
      this.kommunityCategory = this.$route.params.people
    },
    toggleHoverClass(ref) {
      if (typeof this.$refs[ref][0] !== "undefined") {
        this.$refs[ref][0].classList.toggle('hover');
      }
    },
    removeHoverClass(ref) {
      if (typeof this.$refs[ref][0] !== "undefined") {
        this.$refs[ref][0].classList.remove('hover');
      }
    },
    /* imagePreload(imageUrl) {
       return globalImagePreload(imageUrl)
     }*/
  },
  computed: {},
  async created() {
    if (this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      this.pageTitle = result.title;
      this.pageContent = result.content;

      wpFetchKommunityPostsByCategory(this.$route.params.people).then(kommunity => {
        //Fill in Pronouns Disclaimer Popup Data in Store
        this.$store.commit('setPopupContent', {name:this.popupName,content:kommunity.pronounDisclaimer.content});
        //fill in persons in local Data
        for (let i = 0; i < kommunity.persons.length; i++) { // Try to Preload Images
          globalImagePreload(kommunity.persons[i].image).then(() => {
            this.wpKommunity.push(kommunity.persons[i])
          })
        }
        this.setKommunityCategory();
        this.$store.state.loadingProgressbar.finish()
      });
    })
  },
  mounted() {
  },
  watch: {},
}
</script>

<style scoped>
.person {
  position: relative;
  /*margin-bottom: 0.5rem;*/
}

.hover-info > div {
  text-align: center;
}

div[class^="person"] {
  text-align: center;
}

.person-title {
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-black);
}

.person-description, .person-pronouns {
  font-weight: 300;
  font-size: 0.8rem;
}

.person-pronouns {
  cursor: pointer;
}

.person-contact a {
  color: var(--color-black);
  font-size: 1.5rem;
}

.person-contact a:hover, .person-sendung > a.sendung-link:hover {
  color: var(--color-pink);
}

.person-sendung > a.sendung-link {
  font-size: 0.8rem;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  transition: all ease var(--transition-time1);
  color: var(--color-black);
  margin: 0 3px;
}

.person-sendung > a.sendung-link:hover {
  letter-spacing: 1px;
}

.hover-layer {
  background-color: hsla(0, 0%, 100%, 0.7);
}
</style>
