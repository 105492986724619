import axios from "axios";

let qcApiUrlBase = 'https://api.kanalk.ch/qc/';


/**
 * Current Song & Artist via QuantumCast API Socket
 * @param wpPath
 * @returns {Promise<AxiosResponse<any>>}
 */
export function qcFetchCurrentSong() {
    return axios
        .get(qcApiUrlBase + '?q=meta')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}