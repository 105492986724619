<template>
  <div id="player" :class="[this.$store.getters.getPlayerPlaying ? 'playing':'paused']">
    <!-- Controls -->
    <div id="controls" class="controls">
      <div class="button-play-pause" @click.prevent="this.$store.dispatch('togglePlayer',getCurrentPlayerAttrs)">
        <SvgPlayerButtonPause container-class="played"></SvgPlayerButtonPause>
        <SvgPlayerButtonPlay container-class="paused"></SvgPlayerButtonPlay>
      </div>
    </div>
    <!-- Songanzeige -->
    <div id="songanzeige" class="songanzeige">
      <div id="">
        <div class="meta"
             v-if="this.$store.getters.getPlayerMode === 'stream' && (this.$store.getters.getRadiotxtSongCurrent || this.$store.getters.getRadiotxtProgramCurrentTitle)">
          <div>Jetzt Live:</div>
          <div class="current">
            <div class="program-current" id="program-current"
                 v-if="this.$store.getters.getRadiotxtProgramCurrentProgramId">
<!--              TODO-->
              <router-link v-bind:to="'/sendung/' + this.$store.getters.getRadiotxtProgramCurrentProgramId"
                           v-html="this.$store.getters.getRadiotxtProgramCurrentTitle"/>
            </div>
            <div class="song" id="song" v-if="this.$store.getters.getRadiotxtSongCurrent"
                 v-html="this.$store.getters.getRadiotxtSongCurrent"/>
          </div>
          <div class="program-next" id="program-next" v-if="this.$store.getters.getRadiotxtProgramNextStart">
            <span>Ab </span>
            <span v-html="this.$store.getters.getRadiotxtProgramNextStart"/>
            <span>:&nbsp;&nbsp;</span>
            <router-link v-bind:to="'/sendung/' + this.$store.getters.getRadiotxtProgramNextProgramId"
                         v-html="this.$store.getters.getRadiotxtProgramNextTitle"/>
          </div>
        </div>
        <div class="meta" v-else-if="this.$store.getters.getPlayerMode === 'podcast'">
          <div>Podcast:</div>
          <div class="podcasttitle">
            <router-link v-bind:to="this.$store.getters.getPodcastEpisodeRoutePath" v-html="getPodcastSendungTitle"/>
          </div>
          <div class="stream-link" id="program-next">
            <a href="#" @click.prevent="this.$store.dispatch('togglePlayer',getStreamPlayerAttrs)">(wieder Kanal K
              Stream hören)</a>
          </div>
        </div>
      </div>
    </div>
    <BoxProgrammPlaylist wrapper-class=""></BoxProgrammPlaylist>
  </div>
  <!--    <BoxProgrammPlaylist wrapper-class="d-block d-lg-none program-playlist-wrapper"></BoxProgrammPlaylist>-->
</template>

<script>
import SvgPlayerButtonPlay from "@/components/svg/SvgPlayerButtonPlay";
import SvgPlayerButtonPause from "@/components/svg/SvgPlayerButtonPause";
import BoxProgrammPlaylist from "@/components/BoxProgrammPlaylist";

export default {
  name: "PartPlayer",
  components: {
    BoxProgrammPlaylist,
    SvgPlayerButtonPlay, SvgPlayerButtonPause,
  },
  data() {
    return {
      metaPlaylist: null,
      pbProgram: null,
    }
  },
  methods: {},
  computed: {
    getPodcastSendungTitle() {
      if (this.$store.getters.getPodcastSendungTitle) {
        return this.$store.getters.getPodcastSendungTitle + ' - ' + this.$store.getters.getPodcastTitle
      } else {
        return this.$store.getters.getPodcastTitle;
      }
    },
    getCurrentPlayerAttrs() {
      return {
        'playerMode': this.$store.getters.getPlayerMode,
        'podcastAudioUrl': this.$store.getters.getPodcastAudioUrl,
        'podcastTitle': '',
      };
    },
    getStreamPlayerAttrs() {
      return {
        'playerMode': 'stream',
        'podcastAudioUrl': this.$store.getters.getStreamAudioUrl,
        'podcastTitle': '',
      };
    }
  },
}
</script>

<style scoped>
#player {
  position: fixed;
  height: var(--header-height);
  top: 0 !important;
  border-top: solid transparent var(--border-width-thin);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 33%;
  z-index: 8;
  border: solid var(--color-black) var(--border-width-thin);
  transition: opacity ease var(--transition-time1), transform ease var(--transition-time1)
}

.landing.player-display-full #player {
  opacity: 0;
  transform: translate(-50%, var(--transition-margin-minus));
}

#player #controls {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-random1);
}

#player #controls .button-play-pause {
  transition: height 200ms ease, width 200ms ease;
  cursor: pointer;
  width: var(--player-width);
  height: var(--player-height);
}

#player #controls .button-play-pause > div {
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ease var(--transition-time1);
}

#player #controls .button-play-pause > div.paused {
  transform: scale(1) translateX(13%);
}

#player.paused #controls .button-play-pause > div.played,
#player.playing #controls .button-play-pause > div.paused {
  transform: scale(0);
}

#player #controls .button-play-pause svg .playbtn2, player #controls .button-play-pause svg .pausebtn3 {
  fill: var(--color-random);
}

#player #songanzeige {
  position: absolute;
  bottom: 0;
  width: calc(100% + 2 * var(--border-width-thin));
  transform: translateY(100%);
  border: solid var(--color-black) var(--border-width-thin);
  background-color: var(--color-transparent);
  background-color: var(--color-white);
  overflow: hidden;
  height: var(--song-marquee-height)
}

#player #songanzeige .meta > .current,
#player #songanzeige .meta > .podcasttitle {
  font-weight: 600;
}

#player #songanzeige > div {
  display: inline-block;
  padding-left: 100%;
  width: max-content;
  animation: marquee var(--marquee-time) linear infinite;
}

#player #songanzeige:hover > div {
  animation-play-state: paused;
}

#player #songanzeige > div > .meta div {
  display: inline-block;
  margin: 0 var(--border-width-bold);
  margin: 0 3px;
}

#player #songanzeige > div > .meta .program-current a,
#player #songanzeige > div > .meta .program-next a,
#player #songanzeige > div > .meta .stream-link a,
#player #songanzeige > div > .meta .podcasttitle a {
  color: var(--color-black);
  text-decoration: none;
}

#player #songanzeige > div > .meta .stream-link {
  /*font-size: 0.5rem;*/
  margin-left: 1rem;
}

#player #songanzeige > div > .meta .program-current a:hover,
#player #songanzeige > div > .meta .program-next a:hover,
#player #songanzeige > div > .meta .stream-link a:hover,
#player #songanzeige > div > .meta .podcasttitle a:hover {
  text-decoration: underline;
}

#player #songanzeige > div > .meta div.current > .song {
  margin: 0 var(--inline-sides-margin);
  font-style: italic;
}

@media (max-width: 992px) {
  #player {
    display: flex;
    border: solid transparent var(--border-width-thin);
  }

  #player #songanzeige {
    width: 100vw;
    transform: translateY(100%);
    background-color: var(--color-random1);
    overflow: hidden;
    padding: 0;
  }

  #player #songanzeige .meta > .current {
    padding: 0rem var(--box-margin);
  }

  #player #controls {
    background-color: transparent;
    border-color: transparent;
  }
}
</style>
