<template>
  <div class="content">
    <ErrorPageNotFound text="Diese Seite existiert nicht" v-if="error404"></ErrorPageNotFound>
    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div class="text mb-1" v-if="pageContent" v-html="pageContent"/>
      </Transition>
      <Transition name="content">
        <form id="trackservice" class="" @submit.prevent="submitForm" v-if="form.show"
              :class="[!formValidation && formButtonClick ? 'error':'']">
          <div class="row gut-15px gut-sm-5px">
            <div class="col-sm-6">
              <div class="date" :class="[formDateStartStatus]">
                <Datepicker v-model="form.dateStart" format="dd.MM.yyyy HH:mm" locale="de" cancelText="abbrechen"
                            selectText="auswählen" placeholder="Zeitraum von" hideInputIcon autoApply/>
                <div @click.prevent="resetFormDateStart()" class="reset"><i class="fa fa-close"></i></div>
              </div>
              <Transition name="image">
                <div v-if="!formValidationStart && this.form.buttonClick" class="medium-small mt--1 mb-1 text"
                     v-html="this.form.errorStart"/>
              </Transition>
            </div>
            <div class="col-sm-6">
              <div class="date" :class="[formDateEndStatus]">
                <Datepicker v-model="form.dateEnd" format="dd.MM.yyyy HH:mm" locale="de" cancelText="abbrechen"
                            selectText="auswählen" placeholder="Zeitraum bis" hideInputIcon autoApply/>
                <div @click.prevent="resetFormDateEnd()" class="reset"><i class="fa fa-close"></i></div>
              </div>
              <Transition name="image">
                <div v-if="!formValidationEnd && this.form.buttonClick" class="medium-small mt--1 mb-1 text"
                     v-html="this.form.errorEnd"/>
              </Transition>
            </div>
          </div>
          <button class="btn " type="submit">Suchen</button>
        </form>
      </Transition>
      <Transition name="content">
        <div class="mt-1" v-if="this.trackserviceData">
          <div class="text" v-if="!this.trackserviceData.songs" v-html="this.trackserviceData.status"/>
          <div class="songs" v-else>
            <div v-for="(song, s) in this.trackserviceData.songs" :key="s" class="song">
              <div class="datetime ">
                {{ song.date }} - {{ song.time }}
              </div>
              <div class="artisttitle ">
                {{ song.artist }} - {{ song.title }}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import {wpFetchPageData} from "@/api/wordpress";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {digiFetchPlaylistFull} from "@/api/digi";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  name: "TrackserviceView",
  components: {ErrorPageNotFound, Datepicker},
  data() {
    return {
      error404: false,
      pageTitle: null,
      pageContent: null,
      form: {
        show: false,
        error: false,
        errorStart: false,
        errorEnd: false,
        buttonClick: false,
        dateStart: '',
        dateEnd: '',
      },
      trackserviceData: false
    }
  },
  methods: {
    resetFormDateStart() {
      this.form.dateStart = '';
      this.resetForm();
    },
    resetFormDateEnd() {
      this.form.dateEnd = '';
      this.resetForm();
    },
    resetForm() {
      this.form.buttonClick = false;
      this.trackserviceData = '';
    },
    submitForm() {
      this.form.buttonClick = true;
      if (this.formValidation) {
        this.form.error = false;
        if(this.$progress.state().active) {
          this.$store.state.loadingProgressbar = this.$progress.start();
          this.$store.state.loadingProgressbar.finish()
        }
        this.$store.state.loadingProgressbar = this.$progress.start();
        digiFetchPlaylistFull(this.form.dateStart, this.form.dateEnd).then(result => {
          //Fill Trackservice Data
          this.trackserviceData = result
          this.$store.state.loadingProgressbar.finish()
        })
      } else {
        this.form.errorStart = !this.formValidationStart ? "Bitte Zeitpunkt angeben" : false;
        this.form.errorEnd = !this.formValidationEnd ? "Bitte Zeitpunkt angeben" : false;
      }
    }
  },
  watch: {},
  computed: {
    formDateStartStatus() {
      return this.form.dateStart === '' ? 'reset-button-off' : 'reset-button-on';
    },
    formDateEndStatus() {
      return this.form.dateEnd === '' ? 'reset-button-off' : 'reset-button-on';
    },
    formValidation() {
      return this.form.dateStart && this.form.dateEnd ? true : false;
      // return !!this.form.date;
    },
    formValidationStart() {
      return !!this.form.dateStart;
    },
    formValidationEnd() {
      return !!this.form.dateEnd;
    },
    formButtonClick() {
      return !!this.form.buttonClick;
    }
  },
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      //Fill Data
      this.pageTitle = result.title;
      this.pageContent = result.content;
      this.form.show = true;
      this.$store.state.loadingProgressbar.finish()
    })
  },
  mounted() {
  }
}
</script>
<style scoped>
form .date {
  display: flex;
  overflow: hidden;
}

/*ul.songs {*/
/*  list-style: none;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/
/*.songs > li {
  display: flex;
  align-items: center;
}*/

.song:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.songs > .song > .datetime {
  font-size: 0.5rem;
  /*margin-right: 1rem;*/
  /*min-width: 110px;*/
}

.dp__main {
  width: 100%;
}


</style>
<style>

.dp__input {
  background-color: initial;
  border-radius: 0px;
  font-family: 'Inter', sans-serif !important;
  outline: none;
  transition: all ease var(--transition-time1);
  color: initial;
  box-sizing: border-box;

  border: var(--color-black) solid var(--border-width-thin);
  width: 100%;
  font-size: 1.175rem;
  line-height: 1.937rem;
  padding: 0rem 0.4rem 0.1rem 0.4rem;
}

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: var(--color-random1);
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}
</style>
