<template>
  <div :class="containerClass">
    <ul id="menu-sekundaermenu-1" class="menu">
      <li v-for="(menuItem, m) in this.$store.getters.getMenuItemsSecondary1" :key="m" class="">
        <router-link v-bind:to="menuItem.link" v-html="menuItem.title"></router-link>
        <div v-for="(menuNestedItem, mn) in menuItem.nested" :key="mn" class="nested">
          <router-link class="" v-html="menuNestedItem.title" v-bind:to="menuNestedItem.link"></router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuSecondary1",
  props: {
    containerClass: String
  },
  data() {
    return {
      pageTitle: null,
      pageContent: null,
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
