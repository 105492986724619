import {createStore} from "vuex";
import {digiFetchPlaylist} from "@/api/digi";
import {qcFetchCurrentSong} from "@/api/qc";
import {pbFetchProgramCurrent, pbFetchProgramNext, pbFetchProgramToday} from "@/api/pluxbox";
import {streamFetchStreamUrl} from "@/api/stream";

export const store = createStore({
    state() { //state
        return {
            isScrolled: false, // activePage: '',
            loadingProgressbar: false,
            loadingProgressbarPostSlider: [],
            loadingProgressbarInit: [],
            preload: {
                sendungen: false,
                postSlider: [],
            },
            popup: {
                kommunityPronounsDisclaimer: {
                    display: false,
                    content: ""
                }
            },
            menu: {
                menuBar: false, //Menu Bar Toggle
                //Submenu Toggles
                subMenuAusbildung: false,
                subMenuKommunity: false,
                subMenuMusik: false,
                subMenuSendungen: false,
                subMenuPodcasts: false,
            },
            menuItems: {
                primary: false,
                secondaryOne: false,
                secondaryTwo: false,
            },
            colorPalette: {
                black: '#000000',
                // black: '212529',
                white: '#FFFFFF',
                transparent: 'rgba(255,255,255,0.75)',
                red: '#d46e70',
                yellow: '#f5dc70',
                blue: '#70c0eb',
                pink: '#dbb3c5', //pinklight: '#dbb3c5BF',
                green: '#6fbe97',
            }, //Player
            player: {
                // playerEl: this.$refs.audio,
                display: 'full',
                playing: false,
                mode: 'stream',
                audioSrc: '',
                audioProgress: 0,
            },
            stream: {
                streamUrls: {
                    default: '',
                    stream1: 'https://stream.kanalk.ch/kanalk.mp3',
                    stream2: 'https://stream2.kanalk.ch/kanalk.mp3',
                },
            },
            podcast: {
                title: false,
                episode: false,
                episodeRoutePath: false,
                audioUrl: false,
                sendungTitle: false,
                sendungSlug: false,
            },
            radiotxt: {
                songCurrent: '',
                playlist: '',
                programCurrent: {
                    title: '',
                    programId: ''
                },
                programNext: {
                    title: '',
                    programId: '',
                    start: ''
                },
                programToday: ''
            }, //Toggle Boxes
            box: {
                playlistLanding: false,
                playlist: false,
                programLanding: false,
                program: false,
            },
            privacyBanner: {
                text: false,
            },
        }
    },
    getters: {
        getPlayerPlaying(state) {
            return state.player.playing
        },
        getPlayerMode(state) {
            return state.player.mode;
        },
        getPlayerAudioSrc(state) {
            return state.player.audioSrc;
        },
        getPlayerAudioEl() {
            return document.getElementById('playeraudio');
        },
        getPlayerAudioProgress(state) {
            return state.player.audioProgress;
        },
        getPlayerAudioProgressTime() {
            let playerAudio = document.getElementById('playeraudio')
            let currentTime = playerAudio.currentTime;
            let timeString = new Date(currentTime * 1000).toISOString();
            if (currentTime < 3600) {
                return timeString.substring(14, 19)
            } else {
                return timeString.substring(11, 19)
            }
        },
        getPodcastTitle(state) {
            return state.podcast.title;
        },
        getPodcastSendungTitle(state) {
            return state.podcast.sendungTitle;
        },
        getPodcastEpisodeRoutePath(state) {
            return state.podcast.episodeRoutePath;
        },
        getPodcastAudioUrl(state) {
            return state.podcast.audioUrl;
        },
        getPlayerPlayheadEl() {
            return document.getElementById('playhead')
        },
        getPlayerTimelineEl() {
            return document.getElementById('timeline')
        },
        getStreamStreamUrlDefault(state) {
            return state.stream.streamUrls.default;
        },
        getBoxProgramClass(state) {
            return state.box.program ? (state.box.program == 'expanded' ? 'expanded' : 'loading') : '';
        },
        getBoxPlaylistClass(state) {
            return state.box.playlist ? (state.box.playlist == 'expanded' ? 'expanded' : 'loading') : '';
        },
        getBoxProgramLandingClass(state) {
            return state.box.programLanding ? (state.box.programLanding == 'expanded' ? 'expanded' : 'loading') : '';
        },
        getBoxPlaylistLandingClass(state) {
            return state.box.playlistLanding ? (state.box.playlistLanding == 'expanded' ? 'expanded' : 'loading') : '';
        },
        getRadiotxtSongCurrent(state) {
            return state.radiotxt.songCurrent
        },
        getRadiotxtPlaylist(state) {
            return state.radiotxt.playlist
        },
        getRadiotxtProgramCurrentTitle(state) {
            return state.radiotxt.programCurrent.title
        },
        getRadiotxtProgramCurrentProgramId(state) {
            return state.radiotxt.programCurrent.programId
        },
        getRadiotxtProgramNextTitle(state) {
            return state.radiotxt.programNext.title
        },
        getRadiotxtProgramNextProgramId(state) {
            return state.radiotxt.programNext.programId
        },
        getRadiotxtProgramNextStart(state) {
            return state.radiotxt.programNext.start
        },
        getRadiotxtProgramToday(state) {
            return state.radiotxt.programToday
        },
        getPreloadSendungen(state) {
            return state.preload.sendungen
        },
        getPreloadPostSlider(state) {
            return state.preload.postSlider
        },
        getPopupContentByName: (state) => (popupName) => {
            return state.popup[popupName].content
        },
        getMenuItemsPrimary(state) {
            return state.menuItems.primary
        },
        getMenuItemsSecondary1(state) {
            return state.menuItems.secondaryOne
        },
        getMenuItemsSecondary2(state) {
            return state.menuItems.secondaryTwo
        },
    },
    actions: { //dispatch
        togglePlayer(context, playerAttrs) {
            //Toggle Streamplayer
            if (playerAttrs.playerMode === 'stream') {
                context.dispatch('togglePlayerStream', playerAttrs); //Toggle Stream Player
            }
            // Toggle Podcast Player inkl. Audio
            else if (playerAttrs.playerMode === 'podcast') {
                context.dispatch('togglePlayerPodcast', playerAttrs);
            }
        },
        togglePlayerStream(context) {
            if (!context.getters.getPlayerPlaying || context.getters.getPlayerMode == 'podcast') {
                context.dispatch("startPlayerStream");
            } else {
                context.dispatch("stopPlayerStream");
            }
        },
        togglePlayerPodcast(context, playerAttrs) {
            if ( //Load New Podcast Audio File
                (context.getters.getPlayerMode === 'stream') ||
                (playerAttrs.podcastAudioUrl !== context.getters.getPodcastAudioUrl)
            ) {
                context.dispatch("startPlayerPodcast", playerAttrs);
            } else if ( //Resume Playing Current Podcast
                (!context.state.player.playing) &&
                (playerAttrs.podcastAudioUrl === context.getters.getPodcastAudioUrl)
            ) {
                context.dispatch("playPlayerPodcast");
            } else { //Pause Player
                context.dispatch("pausePlayerPodcast");
            }
        },
        startPlayerStream(context) {
            let playerAudio = context.getters.getPlayerAudioEl;
            playerAudio.setAttribute('src', context.getters.getStreamStreamUrlDefault);
            context.commit("setPlayerAudioSrc", context.getters.getStreamStreamUrlDefault)
            playerAudio.play();
            context.commit("setPlayerPlaying", true);
            context.commit("setPlayerMode", 'stream');
            context.commit("removePodcastAttrs");
        },
        stopPlayerStream(context) {
            let playerAudio = context.getters.getPlayerAudioEl;
            playerAudio.pause();
            playerAudio.setAttribute('src', ''); //Remove Stream Player Audio
            context.commit("setPlayerAudioSrc", '');
            context.commit("setPlayerPlaying", false);
        },
        startPlayerPodcast(context, playerPodcastAttrs) {
            context.commit("setPlayerAudioProgress", 0)
            let playerAudio = context.getters.getPlayerAudioEl;
            playerAudio.setAttribute('src', playerPodcastAttrs.podcastAudioUrl);
            context.commit("setPlayerAudioSrc", playerPodcastAttrs.podcastAudioUrl)
            playerAudio.play();
            context.commit("setPodcastAttrs", playerPodcastAttrs); //Set Attributes in State Variables
            context.commit("setPlayerPlaying", true);
            context.commit("setPlayerMode", 'podcast');
        },
        playPlayerPodcast(context) {
            let playerAudio = context.getters.getPlayerAudioEl;
            playerAudio.play();
            context.commit("setPlayerPlaying", true);
        },
        pausePlayerPodcast(context) {
            let playerAudio = context.getters.getPlayerAudioEl;
            playerAudio.pause();
            context.commit("setPlayerPlaying", false);
        },
        setPlayerAudioForward15s(context) {
            let playerAudio = context.getters.getPlayerAudioEl //load Audio DOM Element
            playerAudio.currentTime += 15
        },
        setPlayerAudioBackward15s(context) {
            let playerAudio = context.getters.getPlayerAudioEl //load Audio DOM Element
            playerAudio.currentTime -= 15
        },
        updatePlayerAudioProgress(context) { //fired in PlayerAudio Component => mounted
            let playerAudio = context.getters.getPlayerAudioEl //load Audio DOM Element
            playerAudio.addEventListener('timeupdate', () => {
                let progress = 100 * (playerAudio.currentTime / playerAudio.duration);
                context.commit("setPlayerAudioProgress", progress)
            });
        },
        clickPlayerTimeline(context, event) { //
            let playerAudio = context.getters.getPlayerAudioEl //load Audio DOM Element
            let playerTimeline = context.getters.getPlayerTimelineEl //load Timeline DOM Element
            let playerTimelineWidth = playerTimeline.offsetWidth;
            let playerPlayhead = context.getters.getPlayerPlayheadEl //Load Playhead Element
            moveplayhead(event);
            // playerAudio.currentTime = context.getters.getPlayerAudioDuration * clickPercent(event);
            playerAudio.currentTime = playerAudio.duration * clickPercent(event);

            function moveplayhead(event) {
                var newPaddLeft = event.clientX - getPosition(playerTimeline);
                if (newPaddLeft == 0 || newPaddLeft == playerTimelineWidth) {
                    playerPlayhead.style.width = newPaddLeft + "px";
                }
                if (newPaddLeft < 0) {
                    playerPlayhead.style.width = "0px";
                }
                if (newPaddLeft > playerTimelineWidth) {
                    playerPlayhead.style.width = playerTimelineWidth + "px";
                }
            }

            // returns click as decimal (.77) of the total timelineWidth
            function clickPercent(event) {
                return (event.clientX - getPosition(playerTimeline)) / playerTimelineWidth;
            }

            // Returns elements left position relative to top-left of viewport
            function getPosition(el) {
                return el.getBoundingClientRect().left;
            }
        },
        fetchStreamStreamUrl(context) {
            streamFetchStreamUrl().then(res => {
                context.commit('setStreamStreamUrlDefault', res)
            });
        },
        closeMenuBarFull(context) {
            context.commit('closeMenuBarFull');
        },
        toggleMenuBar(context) {
            context.commit('toggleMenuBar');
            context.commit('closeBoxAll')
        },
        toggleSubMenu(context, subMenu) {
            switch (subMenu) {
                case 'ausbildung':
                    context.commit('toggleSubMenuAusbildung');
                    break;
                case 'kommunity':
                    context.commit('toggleSubMenuKommunity');
                    break;
                case 'musik':
                    context.commit('toggleSubMenuMusik');
                    break;
                case 'sendungen':
                    context.commit('toggleSubMenuSendungen');
                    break;
                case 'podcasts':
                    context.commit('toggleSubMenuPodcasts');
                    break;
                default:
            }
        },
        toggleBoxProgram(context) {
            if (context.state.box.program) {
                context.commit('closeBoxProgram')
            } else {
                context.commit('closeBoxPlaylist')
                context.commit('toggleBoxProgram', 'loading')
                context.dispatch('fetchRadiotxtProgramToday').then(function () {
                    context.commit('toggleBoxProgram', 'expanded')
                })
            }
        },
        toggleBoxProgramLanding(context) {
            if (context.state.box.programLanding) {
                context.commit('closeBoxProgramLanding')
            } else {
                context.commit('closeBoxPlaylistLanding')
                context.commit('toggleBoxProgramLanding', 'loading')
                context.dispatch('fetchRadiotxtProgramToday').then(function () {
                    context.commit('toggleBoxProgramLanding', 'expanded')
                })
            }
        },
        toggleBoxPlaylist(context) {
            if (context.state.box.playlist) {
                context.commit('closeBoxPlaylist')
            } else {
                context.commit('closeBoxProgram')
                context.commit('toggleBoxPlaylist', 'loading')
                context.dispatch('fetchRadiotxtPlaylist').then(function () {
                    context.commit('toggleBoxPlaylist', 'expanded')
                })
            }
        },
        toggleBoxPlaylistLanding(context) {
            if (context.state.box.playlistLanding) {
                context.commit('closeBoxPlaylistLanding')
            } else {
                context.commit('closeBoxProgramLanding')
                context.commit('toggleBoxPlaylistLanding', 'loading')
                context.dispatch('fetchRadiotxtPlaylist').then(function () {
                    context.commit('toggleBoxPlaylistLanding', 'expanded')
                })
            }
        },
        closeBoxAll(context) {
            context.commit('closeBoxAll');
        },
        fetchRadiotxtSongCurrent(context) {
            qcFetchCurrentSong().then(res => {
                if (res.artistSong != context.state.radiotxt.current) {
                    context.commit('setRadiotxtSongCurrent', res.artistSong)
                    // context.commit('setRadiotxt','songCurrent',res)
                }
            });
        },
        fetchRadiotxtPlaylist(context) {
            digiFetchPlaylist().then(res => {
                if (res != context.state.radiotxt.playlist) {
                    context.commit('setRadiotxtPlaylist', res)
                }
            });
        },
        fetchRadiotxtProgramCurrent(context) {
            pbFetchProgramCurrent().then(res => {
                if (res != context.state.radiotxt.programCurrent) {
                    context.commit('setRadiotxtProgramCurrent', res)
                }
            })
        },
        fetchRadiotxtProgramNext(context) {
            pbFetchProgramNext().then(res => {
                if (res != context.state.radiotxt.programNext) {
                    context.commit('setRadiotxtProgramNext', res)
                }
            })
        },
        fetchRadiotxtProgramToday(context) {
            pbFetchProgramToday().then(res => {
                if (res != context.state.radiotxt.programToday) {
                    context.commit('setRadiotxtProgramToday', res)
                }
            })
        },
    },
    mutations: { //commit
        setPlayerPlaying(state, bool) {
            state.player.playing = bool;
        },
        startPlayerPlaying(state) {
            state.player.playing = true;
        },
        stopPlayerPlaying(state) {
            state.player.playing = false;
        },
        setPlayerMode(state, playerMode) {
            state.player.mode = playerMode;
        },
        setPlayerAudioSrc(state, src) {
            state.player.audioSrc = src;
        },
        setPodcastAttrs(state, podcastAttr) {
            state.podcast.title = podcastAttr.podcastTitle;
            state.podcast.episode = podcastAttr.podcastEpisode;
            state.podcast.episodeRoutePath = podcastAttr.podcastEpisodeRoutePath;
            state.podcast.audioUrl = podcastAttr.podcastAudioUrl;
            state.podcast.sendungTitle = podcastAttr.podcastSendungTitle;
            state.podcast.sendungSlug = podcastAttr.podcastSendungSlug;
        },
        removePodcastAttrs(state) {
            state.podcast.title = '';
            state.podcast.episode = '';
            state.podcast.episodeRoutePath = '';
            state.podcast.audioUrl = '';
            state.podcast.sendungTitle = '';
            state.podcast.sendungSlug = '';
        },
        setPlayerAudioProgress(state, progress) {
            state.player.audioProgress = progress;
        },
        setStreamStreamUrlDefault(state, streamUrl) {
            state.stream.streamUrls.default = streamUrl
        },
        setIsScrolled(state, bool) {
            state.isScrolled = bool;
        },
        setIsScrolledLanding(state, bool) {
            state.isScrolledLanding = bool;
        },
        setPlayerDisplay(state, display) {
            state.player.display = display
        },
        togglePopupDisplay(state, popupData) {
            state.popup[popupData.name].display = !state.popup[popupData.name].display;
        },
        setPopupDisplay(state, popupData) {
            state.popup[popupData.name].display = popupData.display;
        },
        setPopupContent(state, popupData) {
            state.popup[popupData.name].content = popupData.content;
        },
        closeMenuBarFull(state) {
            state.menu.menuBar = false;
            state.menu.subMenuAusbildung = false;
            state.menu.subMenuKommunity = false;
            state.menu.subMenuMusik = false;
            state.menu.subMenuSendungen = false;
            state.menu.subMenuPodcasts = false;
        },
        toggleMenuBar(state) {
            state.menu.menuBar = !state.menu.menuBar;
        },
        toggleSubMenuAusbildung(state) {
            state.menu.subMenuAusbildung = !state.menu.subMenuAusbildung;
        },
        toggleSubMenuKommunity(state) {
            state.menu.subMenuKommunity = !state.menu.subMenuKommunity;
        }, toggleSubMenuMusik(state) {
            state.menu.subMenuMusik = !state.menu.subMenuMusik;
        },
        toggleSubMenuSendungen(state) {
            state.menu.subMenuSendungen = !state.menu.subMenuSendungen;
        },
        toggleSubMenuPodcasts(state) {
            state.menu.subMenuPodcasts = !state.menu.subMenuPodcasts;
        },
        toggleBoxProgram(state, programClass) {
            state.box.program = programClass;
        },
        toggleBoxProgramLanding(state, programClass) {
            state.box.programLanding = programClass;
        },
        toggleBoxPlaylist(state, playlistClass) {
            state.box.playlist = playlistClass;
        },
        toggleBoxPlaylistLanding(state, playlistClass) {
            state.box.playlistLanding = playlistClass;
        },
        closeBoxProgram(state) {
            state.box.program = false;
        },
        closeBoxProgramLanding(state) {
            state.box.programLanding = false;
        },
        closeBoxPlaylist(state) {
            state.box.playlist = false;
        },
        closeBoxPlaylistLanding(state) {
            state.box.playlistLanding = false;
        },
        closeBoxAll(state) {
            state.box.program = false;
            state.box.programLanding = false;
            state.box.playlist = false;
            state.box.playlistLanding = false;
        },
        setRadiotxtSongCurrent(state, current) {
            state.radiotxt.songCurrent = current;
        },
        setRadiotxtPlaylist(state, playlist) {
            state.radiotxt.playlist = playlist;
        },
        setRadiotxtProgramCurrent(state, program) {
            state.radiotxt.programCurrent = program;
        },
        setRadiotxtProgramNext(state, program) {
            state.radiotxt.programNext = program;
        },
        setRadiotxtProgramToday(state, program) {
            state.radiotxt.programToday = program;
        },
        setPreloadSendungen(state, data) {
            state.preload.sendungen = data;
        },
        setPreloadPostSlider(state, data) {
            state.preload.postSlider[data.id] = data.result
        },
        setMenuItemsPrimary(state, data) {
            state.menuItems.primary = data
        },
        setMenuItemsSecondary1(state, data) {
            state.menuItems.secondaryOne = data
        },
        setMenuItemsSecondary2(state, data) {
            state.menuItems.secondaryTwo = data
        },
        setPrivacyBannerText(state, text) {
            state.privacyBanner.text = text
        }
    }
})
