<template>
  <div>
    <Transition name="form">
      <form class="" v-if="form.step == 'form'" @submit.prevent="submitMemberForm"
            action="" method="post">
        <div class="row">
          <div v-for="(input,i) in form.inputs" :key="i" :class="input.class">
            <input v-model="input.value" v-bind:type="input.type" v-bind:id="i" v-bind:name="input.name"
                   v-bind:placeholder="input.name" :class="[input.error ? 'error':'']"/>
            <Transition name="info" v-if="input.type == 'text'">
              <div class="medium-small mt--1 mb-1" v-if="input.error" v-html="input.error"/>
            </Transition>
          </div>
          <div class="col-md-6">
            <div class="strong">Mitgliedschaft</div>
            <div v-for="(memberType, mt) in form.memberTypes" :key="mt">
              <input v-model="form.inputs.memberType.value" @click="setSpendeField(memberType.donationField)"
                     type="radio" v-bind:id="mt"
                     name="memberType" v-bind:value="mt"
                     placeholder="">
              <label @click="setSpendeField(memberType.donationField)" v-bind:for="mt"
                     v-html="memberType.title"/>
            </div>
            <Transition name="info">
              <div class="row" v-if="form.showDonationField">
                <div class="col-lg-6 col-8">
                  <div>
                    <input v-model="form.inputs.donation.value" class="mt-5px mb-0" type="text"
                           id="spende-betrag" name="spende-betrag"
                           placeholder="Spende Betrag" :class="[form.inputs.donation.error ? 'error':'']">
                    <Transition name="info">
                      <div class="medium-small" v-if="form.inputs.donation.error" v-html="form.inputs.donation.error"/>
                    </Transition>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <div class="col-md-6 mt-1 mt-md-0">
            <div class="strong">Zahlung via:</div>
            <div v-for="(paymentType, pt) in form.paymentTypes" :key="pt">
              <input v-model="form.inputs.paymentType.value" type="radio" v-bind:id="pt"
                     name="paymentType" v-bind:value="pt"
                     placeholder="">
              <label v-bind:for="pt"
                     v-html="paymentType.title"/>
            </div>
            <Transition name="info">
              <div class="medium-small" v-if="form.inputs.paymentType.error"
                   v-html="form.inputs.paymentType.error"/>
            </Transition>
            <Transition name="info">
              <div class="medium-small" v-if="getFormInputPaymentTypeInfo" v-html="getFormInputPaymentTypeInfo"/>
            </Transition>
          </div>
        </div>
        <button class="btn mt-1" type="submit">Mitglied werden</button>
      </form>
    </Transition>
    <Transition name="form">
      <div v-if="form.step == 'payment'" class="form-answer success">
        <div class="row">
          <div class="col-lg-10 col-md-12 col-12">
            <div class="strong">Vielen Dank für deine Anmeldung und willkommen in der Kanal K Kommunity.</div>
            <template v-if="getFormInputPaymentTypeValue == 'bank'">
              <div class="text">Bitte bezahle die <strong>{{ getFormInputMemberTypeChf }}</strong> in folgendes Konto ein:<br>
               Interessengemeinschaft Regionalradio, 5000 Aarau<br>
                CH36 0900 0000 5000 7000 0
              </div>
            </template>
            <template v-else-if="getFormInputPaymentTypeValue == 'paypal'">
              <a v-bind:href="form.paypalButtonLinkUrlBase + getFormInputMemberTypePaypalButtonId" target="_blank">
                <button class="btn large mt-1">Bezahle den Beitrag von {{ getFormInputMemberTypeChf }} via Paypal</button>
              </a>
            </template>
            <template v-else-if="getFormInputPaymentTypeValue == 'twint'">
              <a v-bind:href="form.raisenowPaylinkUrlBase + getFormInputMemberTypeTwintRaisenowId" target="_blank">
                <button class="btn large mt-1">Bezahle den Betrag von {{ getFormInputMemberTypeChf }} via Twint</button>
              </a>
            </template>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import {mailMemberForm} from "@/api/mail";
import {globalValidateNumber, globalValidateEmail} from "@/main";

export default {
  name: "FormMember",
  data() {
    return {
      error404: false,
      wpPageData: null,
      pageTitle: null,
      pageContent: null,
      form: {
        step: 'form',
        showDonationField: false,
        showPaymentInfo: false,
        validated: false,
        raisenowPaylinkUrlBase: 'https://pay.raisenow.io/',
        paypalButtonLinkUrlBase: 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=',
        inputs: {
          vorname: {name: 'Vorname', value: '', type: 'text', error: false, class: 'col-md-6'},
          nachname: {name: 'Nachname', value: '', type: 'text', error: false, class: 'col-md-6'},
          strasse: {name: 'Strasse', value: '', type: 'text', error: false, class: 'col-9'},
          nummer: {name: 'Nr.', value: '', type: 'text', error: false, class: 'col-3'},
          plz: {name: 'PLZ', value: '', type: 'text', error: false, class: 'col-4'},
          ort: {name: 'Ort', value: '', type: 'text', error: false, class: 'col-8'},
          telefon: {name: 'Telefon', value: '', type: 'text', error: false, class: 'col-md-6'},
          email: {name: 'Email', value: '', type: 'text', error: false, class: 'col-md-6'},
          memberType: {name: 'Mitgliedschaft', value: 'verdienende-100', type: 'hidden', error: false, class: ''},
          donation: {name: 'Spende', value: '', type: 'hidden', error: false, class: ''},
          paymentType: {name: 'Zahltyp', value: '', type: 'hidden', error: false, class: ''},
        },
        memberTypes: {
          'unregelmaessigverdienende-50': {
            title: 'unregelmässig Verdienende (50.-)',
            chf: '50.-',
            selected: false,
            donationField: false,
            twintRaisenowId: 'jrczk',
            paypalButtonId: 'QW4L86CMFPAPN',
          },
          'verdienende-100': {
            title: 'regelmässig Verdienende (100.-)',
            chf: '100.-',
            selected: true,
            donationField: false,
            twintRaisenowId: 'psgvn',
            paypalButtonId: '2JWHKHAY6P28Q'
          },
          'wgs-150': {
            title: 'WGs (150.-)',
            chf: '150.-',
            selected: false,
            donationField: false,
            twintRaisenowId: 'kmqmv',
            paypalButtonId: '5CKG3NEGN98GS'
          },
          'goenner-innen-200': {
            title: 'Gönner*innen (200.-)',
            chf: '200.-',
            selected: false,
            donationField: false,
            twintRaisenowId: 'kszpj',
            paypalButtonId: 'NBUKP3ZQD4226'
          },
          'organisationen-240': {
            title: 'Organisationen (240.-)',
            chf: '240.-',
            selected: false,
            donationField: false,
            twintRaisenowId: 'kngdq',
            paypalButtonId: 'MX969XS54HL9W',
          },
          'spende': {
            title: 'Spende',
            chf: '',
            selected: false,
            donationField: true,
            twintRaisenowId: 'qvdxd',
            paypalButtonId: 'KKWKNC8TXMQWG'
          }
        },
        paymentTypes: {
          bank: {
            title: 'Bank',
            text: 'Unsere Bankverbindung (lautet auf Interessengemeinschaft Regionalradio, 5000 Aarau):\n\n' +
                'CH36 0900 0000 5000 7000 0'
          },
          paypal: {
            title: 'Paypal',
            text: 'Die Zahlungsmöglichkeiten für Paypal werden nach Absenden des Formulars eingeblendet'
          },
          twint: {
            title: 'Twint',
            text: 'Die Zahlungsmöglichkeiten für Twint werden nach Absenden des Formulars eingeblendet'
          },
        }
      },
    }
  },
  computed: {
    getFormInputPaymentTypeInfo() {
      let pt = this.form.inputs.paymentType.value;
      return this.form.paymentTypes[pt] ? this.form.paymentTypes[pt].text : false;
    },
    getFormInputMemberTypeChf() {
      let mt = this.form.inputs.memberType.value;
      if (mt == 'spende') {
        return this.getFormInputDonationValue + '.-';
      }
      return this.form.memberTypes[mt] ? this.form.memberTypes[mt].chf : false;
    },
    getFormInputMemberTypeTwintRaisenowId() {
      let mt = this.form.inputs.memberType.value;
      return this.form.memberTypes[mt] ? this.form.memberTypes[mt].twintRaisenowId : false;
    },
    getFormInputMemberTypePaypalButtonId() {
      let mt = this.form.inputs.memberType.value;
      return this.form.memberTypes[mt] ? this.form.memberTypes[mt].paypalButtonId : false;
    },
    getFormInputVornameValue() {
      return this.form.inputs.vorname.value;
    },
    getFormInputNachnameValue() {
      return this.form.inputs.nachname.value;
    },
    getFormInputStrasseValue() {
      return this.form.inputs.strasse.value;
    },
    getFormInputNummerValue() {
      return this.form.inputs.nummer.value;
    },
    getFormInputPlzValue() {
      return this.form.inputs.plz.value;
    },
    getFormInputOrtValue() {
      return this.form.inputs.ort.value;
    },
    getFormInputTelefonValue() {
      return this.form.inputs.telefon.value;
    },
    getFormInputEmailValue() {
      return this.form.inputs.email.value;
    },
    getFormInputMemberTypeValue() {
      return this.form.inputs.memberType.value;
    },
    getFormInputDonationValue() {
      return this.form.inputs.donation.value;
    },
    getFormInputPaymentTypeValue() {
      return this.form.inputs.paymentType.value;
    }
  },
  methods: {
    setSpendeField(bool) {
      this.form.showDonationField = bool;
    },
    validateFormInputs() {
      this.form.validated = true;
      if (!this.form.inputs.vorname.value) { //Vorname
        this.form.inputs.vorname.error = "Bitte " + this.form.inputs.vorname.name + " angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.nachname.value) { //Nachname
        this.form.inputs.nachname.error = "Bitte " + this.form.inputs.nachname.name + " angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.strasse.value) { //Strasse
        this.form.inputs.strasse.error = "Bitte " + this.form.inputs.strasse.name + " angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.nummer.value) { //Nummer
        this.form.inputs.nummer.error = "Bitte " + this.form.inputs.nummer.name + " angeben";
        this.form.validated = false;
      }
      // else if (!globalValidateNumber(this.form.inputs.nummer.value)) { //Nr. valid
      //   this.form.inputs.nummer.error = "Bitte gültige Nummer angeben";
      //   this.form.validated = false;
      // }
      if (!this.form.inputs.plz.value) { //plz
        this.form.inputs.plz.error = "Bitte " + this.form.inputs.plz.name + " angeben";
        this.form.validated = false;
      } else if (!globalValidateNumber(this.form.inputs.plz.value)) { //PLZ valid
        this.form.inputs.plz.error = "Bitte gültige Postleitzahl angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.ort.value) { //ort
        this.form.inputs.ort.error = "Bitte " + this.form.inputs.ort.name + " angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.telefon.value) { //telefon
        this.form.inputs.telefon.error = "Bitte Telefonnummer angeben";
        this.form.validated = false;
      } else if (!globalValidateNumber(this.form.inputs.telefon.value)) { //PLZ valid
        this.form.inputs.telefon.error = "Bitte nur Zahlen als Telefonnummer angeben";
        this.form.validated = false;
      }
      if (!this.form.inputs.email.value) { //email
        this.form.inputs.email.error = "Bitte E-Mail Adresse angeben";
        this.form.validated = false;
      } else if (!globalValidateEmail(this.form.inputs.email.value)) { //Email valid?
        this.form.inputs.email.error = "Bitte gültige E-Mail Adresse angeben";
        this.form.validated = false;
      }
      if(this.getFormInputMemberTypeValue == 'spende' && !this.form.inputs.donation.value) { //Donation
        this.form.inputs.donation.error = "Bitte gib einen Betrag ein";
        this.form.validated = false;
      } else if (!globalValidateNumber(this.form.inputs.donation.value)) { //Donation valid
        this.form.inputs.donation.error = "Bitte gibt nur eine Zahl als Betrag ein";
        this.form.validated = false;
      }
      if (!this.form.inputs.paymentType.value) { //paymentType
        this.form.inputs.paymentType.error = "Bitte " + this.form.inputs.paymentType.name + " angeben";
        this.form.validated = false;
      }
    },
    resetFormErrors() {
      this.form.inputs.vorname.error = '';
      this.form.inputs.nachname.error = '';
      this.form.inputs.strasse.error = '';
      this.form.inputs.nummer.error = '';
      this.form.inputs.plz.error = '';
      this.form.inputs.ort.error = '';
      this.form.inputs.telefon.error = '';
      this.form.inputs.email.error = '';
      this.form.inputs.paymentType.error = '';
    },
    validateForm() {
      //Empty All Errors
      this.resetFormErrors();
      //Validate Form Inputs
      this.validateFormInputs();
    },
    submitMemberForm() {
      this.validateForm();
      if (this.form.validated) {
        if (this.$progress.state().active) {
          this.$store.state.loadingProgressbar.finish()
        }
        this.$store.state.loadingProgressbar = this.$progress.start();
        //Send Email in the Background
        const formData = new FormData();
        for (let key in this.form.inputs) {
          formData.append(key, this.form.inputs[key].value)
        }
        formData.append('mailTo', 'info@kanalk.ch')
        formData.append('mailSubject', 'Neues Kommunity Anmeldung Formular')
        mailMemberForm(formData).then(result => {
          if (result.status == 'success') {
            //Go to Payment Step
            this.form.step = 'payment';
          }
          this.$store.state.loadingProgressbar.finish()
        })
      }
    },
      },
  watch: {
    getFormInputVornameValue() {
      this.form.inputs.vorname.error = '';
    },
    getFormInputNachnameValue() {
      this.form.inputs.nachname.error = '';
    },
    getFormInputStrasseValue() {
      this.form.inputs.strasse.error = '';
    },
    getFormInputNummerValue() {
      this.form.inputs.nummer.error = '';
    },
    getFormInputPlzValue() {
      this.form.inputs.plz.error = '';
    },
    getFormInputOrtValue() {
      this.form.inputs.ort.error = '';
    },
    getFormInputTelefonValue() {
      this.form.inputs.telefon.error = '';
    },
    getFormInputEmailValue() {
      this.form.inputs.email.error = '';
    },
    getFormInputMemberTypeValue() {
      this.form.inputs.memberType.error = '';
    },
    getFormInputDonationValue() {
      this.form.inputs.donation.error = '';
    },
    getFormInputPaymentTypeValue() {
      this.form.inputs.paymentType.error = '';
    }
  },
  mounted() {},
}
</script>

<style scoped>

</style>