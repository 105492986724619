<template>
  <div :class="containerClass">
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 196.83 263.57" style="enable-background:new 0 0 196.83 263.57;" xml:space="preserve">
    <rect x="0.98" y="0.98" class="pausebtn0" width="67.4" height="261.44"/>
    <g>
	<defs>
		<rect id="SVGID_1_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000113343903813376310610000003848252330765315460_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="0.97" y="0.97" style="clip-path:url(#SVGID_00000113343903813376310610000003848252330765315460_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.4" height="261.44"/>
</g>
    <rect x="128.51" y="1.18" class="pausebtn0" width="67.35" height="261.41"/>
    <g>
	<defs>
		<rect id="SVGID_00000026842911768386250180000002242535220586951356_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000152231178378074083170000012933869254388124323_">
		<use xlink:href="#SVGID_00000026842911768386250180000002242535220586951356_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="128.51" y="1.18" style="clip-path:url(#SVGID_00000152231178378074083170000012933869254388124323_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.35" height="261.42"/>
</g>
    <rect x="0.98" y="16.61" class="pausebtn3" width="67.4" height="230.2"/>
    <g>
	<defs>
		<rect id="SVGID_00000003072572451918587140000001153241596780958343_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000137811377445275812400000005820648864065666701_">
		<use xlink:href="#SVGID_00000003072572451918587140000001153241596780958343_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="0.97" y="16.61" style="clip-path:url(#SVGID_00000137811377445275812400000005820648864065666701_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.4" height="230.2"/>
</g>
    <rect x="128.51" y="16.79" class="pausebtn3" width="67.35" height="230.18"/>
    <g>
	<defs>
		<rect id="SVGID_00000101080335280226781890000010904208979359901314_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000016036368025870061130000015604951144268767661_">
		<use xlink:href="#SVGID_00000101080335280226781890000010904208979359901314_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="128.51" y="16.79" style="clip-path:url(#SVGID_00000016036368025870061130000015604951144268767661_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.35" height="230.18"/>
</g>
    <rect x="0.98" y="32.2" width="67.4" height="199.04"/>
    <g>
	<defs>
		<rect id="SVGID_00000061445537440545389350000015844630248010710947_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000019641994694255648200000017087716558667298225_">
		<use xlink:href="#SVGID_00000061445537440545389350000015844630248010710947_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="0.97" y="32.2" style="clip-path:url(#SVGID_00000019641994694255648200000017087716558667298225_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.4" height="199.04"/>
</g>
    <rect x="128.51" y="32.36" width="67.35" height="199.02"/>
    <g>
	<defs>
		<rect id="SVGID_00000015316715595051298780000011000069941270193319_" width="196.83" height="263.57"/>
	</defs>
      <clipPath id="SVGID_00000072987338193122145580000004438308851564672416_">
		<use xlink:href="#SVGID_00000015316715595051298780000011000069941270193319_"  style="overflow:visible;"/>
	</clipPath>

      <rect x="128.51" y="32.36" style="clip-path:url(#SVGID_00000072987338193122145580000004438308851564672416_);fill:none;stroke:#000000;stroke-width:1.949;" width="67.35" height="199.02"/>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: "SvgPlayerButtonPause",
  props: {
    containerClass: String
  }
}
</script>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
.pausebtn0{fill:#FFFFFF;}
.pausebtn1{clip-path:url(#SVGID_00000065753925646411997700000009801681283185754242_);fill:none;stroke:#000000;stroke-width:1.949;}
.pausebtn2{clip-path:url(#SVGID_00000080926556255617903790000006957571781104978822_);fill:none;stroke:#000000;stroke-width:1.949;}
.pausebtn3{fill:var(--color-random1);}
.pausebtn4{clip-path:url(#SVGID_00000044172448817915622310000010513251034784851359_);fill:none;stroke:#000000;stroke-width:1.949;}
.pausebtn5{clip-path:url(#SVGID_00000100372738143405588290000017155972106918694300_);fill:none;stroke:#000000;stroke-width:1.949;}
.pausebtn6{clip-path:url(#SVGID_00000094596956643008159960000018272910916167512194_);fill:none;stroke:#000000;stroke-width:1.949;}
.pausebtn7{clip-path:url(#SVGID_00000147931553261244218230000003265233686017392258_);fill:none;stroke:#000000;stroke-width:1.949;}
</style>