<template>
  <div id="content-wrapper"
       :class="[this.$store.state.menu.menuBar ? 'menu-bar-opened':'', this.$store.state.isScrolled?'scrolled':'not-scrolled', this.playerDisplay, this.$route.name]">
    <vue3-progress-bar></vue3-progress-bar>
    <PartHeader></PartHeader>
    <!-- Content Views -->
    <main id="content">
      <router-view v-if="routerViewNeedsUniqueKey" :key="$route.path"></router-view>
      <router-view v-else></router-view>

    </main>
    <PartFooter v-if="this.$route.name != 'player'"></PartFooter>
    <PartPlayerAudio></PartPlayerAudio>

    <!-- Privacy / Cookie Consent Bar -->
    <vue-cookie-accept-decline v-if="this.$store.state.privacyBanner.text"
                               :debug="false"
                               :disableDecline="true"
                               :showPostponeButton="false"
                               elementId="privacy-bar"
                               ref="cookies"
                               transitionName="slideFromBottom"
                               type="bar"
    >
      <!-- Optional -->
      <template #postponeContent>&times;</template>
      <!-- Optional -->
      <template #message>
        <div class="privacy-banner-content">
          <span v-html="this.$store.state.privacyBanner.text"/>
          <router-link to="/datenschutz" class="privacy-link">
            <button class="btn cookie__bar__buttons__button--privacy">Datenschutz</button>
          </router-link>
        </div>
      </template>
      <!-- Optional -->
      <template #declineContent>Ablehnen</template>
      <!-- Optional -->
      <template #acceptContent>Akzeptieren</template>
      asdfasdf
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import PartHeader from "@/components/PartHeader";
import PartFooter from "@/components/PartFooter";
import PartPlayerAudio from "@/components/PartPlayerAudio";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import {
  wpFetchSendungPosts,
  wpFetchWidgetContent,
  wpFetchMenuItemsPrimary,
  wpFetchMenuItemsSecondaryOne
} from "@/api/wordpress";
import {globalIsInternalHref, globalRelativizeHref} from "@/main";
import {useCookies} from "vue3-cookies";
import {useProgress} from "@marcoschulte/vue3-progress";

export default {
  name: "KanalK",
  components: {
    VueCookieAcceptDecline,
    PartPlayerAudio,
    PartFooter, PartHeader
  },
  methods: {
    detectIsScrolled() {
      let isScrolledThreshold = 85;
      if (window.scrollY > isScrolledThreshold) {
        this.$store.commit('setIsScrolled', true)
      } else {
        this.$store.commit('setIsScrolled', false)
      }
    },

    closeProgramPlaylistBoxes(deltaY) {
      if (deltaY > 40 || deltaY < -40) {
        this.$store.commit('closeBoxPlaylist')
        this.$store.commit('closeBoxProgram')
      }
    }
  },
  computed: {
    routerViewNeedsUniqueKey() { //check if Router View needs unique Key
      return (this.$route.name == 'page program' || this.$route.name == 'page program redirect' || this.$route.name == 'page program day') ? false : true;
    },
    playerDisplay() { //return for correct class, to display player full or default (Landing only)
      return this.$route.name == 'landing'?'player-display-'+this.$store.state.player.display:'';
    }
  },
  setup() {
    //TODO
    // console.log(cookies.remove('_ga'));
    // console.log(cookies.remove('_ga_ZS86KS94M7'));
    const {cookies} = useCookies();
    // // cookies.remove('_ga_ZS86KS94M7');
    // // cookies.remove('_ga');
    // console.log(cookies.keys());
    return {cookies};
  },
  created() {
    /*Is Scrolled Detection*/
    window.addEventListener('scroll', this.detectIsScrolled);
    this.detectIsScrolled();

    /*Close Programm/Playlist Boxes when scrolling a certain amoount: Mousewheel */
    window.addEventListener('wheel', (e) => {
      this.closeProgramPlaylistBoxes(e.deltaY)
    });

    /*Close Programm/Playlist Boxes when scrolling a certain amoount: Smartphone*/
    let startY;
    window.addEventListener('touchmove', (e) => {
      let deltaY = (e.touches[0].clientY - startY) / 5;
      this.closeProgramPlaylistBoxes(deltaY)
    });
    window.addEventListener('touchstart', (e) => {
      startY = e.touches[0].clientY;
    });

    /*Color Palette*/
    const style = document.documentElement.style;
    const colorPalette = Object.assign({}, this.$store.state.colorPalette);
    const colorPaletteText = Array('black', 'red', 'yellow', 'blue', 'pink', 'green');
    /*Generate Array with 2 unique nr. 1-4 */
    var randomNrs = [];
    while (randomNrs.length < 2) {
      var r = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
      if (randomNrs.indexOf(r) === -1) randomNrs.push(r);
    }
    const randomColor1 = colorPaletteText[randomNrs[0]]
    const randomColor2 = colorPaletteText[randomNrs[1]]
    for (const key in colorPalette) {
      style.setProperty('--color-' + key, colorPalette[key]);
      style.setProperty('--color-' + key + '-op7', colorPalette[key] + 'B3');

    }
    style.setProperty('--color-random1', colorPalette[randomColor1]);
    style.setProperty('--color-random1-op7', colorPalette[randomColor1] + 'B3');
    style.setProperty('--color-random2', colorPalette[randomColor2]);
    style.setProperty('--color-random2-op7', colorPalette[randomColor2] + 'B3');

    /**
     * Load Stuff in Vuex Store
     * add new Loading Bar instance this.$store.state.loadingProgressbarInit for every Load Init
     */
    /* Load Privacy Banner Text */
    this.$store.state.loadingProgressbarInit['privacy-banner'] = useProgress().start();
    wpFetchWidgetContent('privacy-banner').then(widget => {
      this.$store.commit('setPrivacyBannerText', widget.content)
      this.$store.state.loadingProgressbarInit['privacy-banner'].finish()
    })

    /* Load Menu Items into Store via Api */
    /* Primary Menu */
    this.$store.state.loadingProgressbarInit['menu-primary'] = useProgress().start();
    wpFetchMenuItemsPrimary().then(items => {
      this.$store.commit('setMenuItemsPrimary', items);
      this.$store.state.loadingProgressbarInit['menu-primary'].finish();
    });
    /* Secondary Menu 1 */
    this.$store.state.loadingProgressbarInit['menu-secondary-one'] = useProgress().start();
    wpFetchMenuItemsSecondaryOne().then(items => {
      this.$store.commit('setMenuItemsSecondary1', items);
      this.$store.state.loadingProgressbarInit['menu-secondary-one'].finish();
    });
    /* Secondary Menu 2 */
    /* this.$store.state.loadingProgressbarInit['menu-secondary-two'] = useProgress().start();
     wpFetchMenuItemsSecondaryTwo().then(items => {
       this.$store.commit('setMenuItemsSecondary2', items);
       this.$store.state.loadingProgressbarInit['menu-secondary-two'].finish();
     });*/

    /* Load Stream URL */
    this.$store.dispatch("fetchStreamStreamUrl");

    /*Radiotext Update Anzeige*/
    this.$store.dispatch('fetchRadiotxtSongCurrent')
    this.$store.dispatch('fetchRadiotxtPlaylist')
    this.$store.dispatch('fetchRadiotxtProgramCurrent')
    this.$store.dispatch('fetchRadiotxtProgramNext')
    this.$store.dispatch('fetchRadiotxtProgramToday')
    window.setInterval(() => {
      this.$store.dispatch('fetchRadiotxtSongCurrent');
    }, 5000)
    window.setInterval(() => {
      this.$store.dispatch('fetchRadiotxtProgramCurrent')
      this.$store.dispatch('fetchRadiotxtProgramNext')
    }, 120000)

    /*Load all Sendungen into Store (Preload)*/
    wpFetchSendungPosts().then(sendungen => {
      this.$store.commit('setPreloadSendungen', sendungen)
    });
  },
  mounted() {
    //Replace Standard Anchors with Vue Router in #content
    let content = document.getElementById('content');
    content.addEventListener('click', event => {
      let target = event.target;
      /*while (target != null && target.tagName != 'A') {
        target = target.parentNode;
      }*/
      if (target.tagName == 'A') { //only A Tags
        if (target && target.href && globalIsInternalHref(target.href)) { //internal Link
          event.preventDefault();
          this.$router.push(globalRelativizeHref(target.href));
        }
        /*else { //external Link (incl. Downloads) --> deactivated beacause: it openes external links twice
          if(globalGetUrlProtocol(target.href) != 'mailto:') {
            event.preventDefault();
            window.open(target.href, '_blank');
          }
        }*/
      }
    });
  },
  unmounted() {
    window.removeEventListener('scroll', this.detectIsScrolled);
  }
}
</script>
<style>
@import "~@marcoschulte/vue3-progress/dist/";
@import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/css/fw.min.css";
@import "./assets/css/global.css";
</style>
