<template>
    <PartPlayerLanding></PartPlayerLanding>
</template>

<script>
import PartPlayerLanding from "@/components/PartPlayerLanding";
export default {
  name: "PlayerView",
  components: {PartPlayerLanding}
}
</script>

<style scoped>

</style>