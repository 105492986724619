<template>
  <Transition name="subtitle">
    <h4 v-if="subTitle" v-html="subTitle"/>
  </Transition>
    <TransitionGroup name="subcontent">
      <div v-for="(prg, i) in program" :key="i" class="row text">
        <div class="col-2 col-md-1">{{ prg.start }}</div>
        <div class="col-10 col-md-11">
          <router-link go v-bind:to="'/sendung/' + prg.programId"
                       @click.prevent="this.$store.commit('closeBoxProgram')">
            {{ prg.title }}
          </router-link>
        </div>
      </div>
    </TransitionGroup>
</template>

<script>
import {pbFetchProgramDay} from "@/api/pluxbox";

export default {
  name: "ProgrammDayView",
  data() {
    return {
      error404: false,
      subTitle: null,
      program: null
    }
  },
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    pbFetchProgramDay(this.$route.params.day).then(result => {
      //check 404
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      this.subTitle = result.title;
      this.program = result.program;
      this.$store.state.loadingProgressbar.finish()
    });
  }
}
</script>

<style scoped>

</style>
