<template>
  <div class="program-playlist" :class="[wrapperClass]" id="programm-playlist">
    <!-- Programm Info Box -->
    <div id="programm-box" class="box program" :class="this.$store.getters.getBoxProgramLandingClass">
      <div class="header" @click.prevent="[this.$store.dispatch('toggleBoxProgramLanding'), this.$store.getters.getBoxProgramLandingClass ? scrollToBox() : '']">
        <div class="arrow">
          <div>
            <SvgArrow></SvgArrow>
          </div>
        </div>
        <div class="title">Programm</div>
      </div>
      <div class="expand-list">
        <div v-for="(prg, i) in this.$store.getters.getRadiotxtProgramToday" :key="i" class="prg-wrapper">
          <div class="prg-time">{{ prg.start }} - {{ prg.stop }}</div>
          <div class="prg-title">
            <router-link v-bind:to="'/sendung/' + prg.programId"
                         @click.prevent="this.$store.commit('closeBoxProgramLanding')">
              {{ prg.title }}
            </router-link>
          </div>
        </div>
        <div class="prg-wrapper more">
          <div class="prg-title">
          <router-link @click.prevent="this.$store.commit('closeBoxAll')" to="/programm">Wochenprogramm</router-link>
        </div>
        </div>
      </div>
    </div>
    <!-- Playlist Info Box -->
    <div id="playlist-box" class="box playlist" :class="this.$store.getters.getBoxPlaylistLandingClass">
      <div class="header" @click.prevent="this.$store.dispatch('toggleBoxPlaylistLanding'), this.$store.getters.getBoxPlaylistLandingClass ? scrollToBox() : ''">
        <div class="arrow">
          <SvgArrow></SvgArrow>
        </div>
        <div class="title">Abgespielt</div>
      </div>
      <div class="expand-list">
        <div v-for="(prg, i) in this.$store.getters.getRadiotxtPlaylist" :key="i" class="prg-wrapper">
          <div class="prg-time">{{prg.date}} - {{ prg.time }}</div>
          <div class="prg-title">
            {{ prg.artist }} - {{ prg.title }}
          </div>
        </div>
        <div class="prg-wrapper more">
          <div class="prg-title">
          <router-link @click.prevent="this.$store.commit('closeBoxAll')" to="/trackservice">Trackservice</router-link>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgArrow from "@/components/svg/SvgArrow";

export default {
  name: "BoxProgrammPlaylistLanding",
  inject: ["mq"],
  components: {
    SvgArrow
  },
  props: {
    wrapperClass: String
  },
  methods: {
    scrollToBox() {
      if (this.mq.mdMinus) {
        let scrollPos = document.getElementById('player-landing').offsetHeight / 2;
        window.scrollTo({top: scrollPos, behavior: 'smooth'});
      }
    }
  },
  created() {
  }
}
</script>

<style scoped>
.program-playlist .box {
  left: 0;
  transform: translate(-100%, 0);
  display: flex;
}

.program-playlist .box.playlist {
  left: initial;
  right: 0;
  transform: translate(100%);
  border: solid var(--color-black) var(--border-width-thin);
}

.program-playlist .box > .header {
  width: 40px;
}

.program-playlist .box.program > .header {
  border-right: solid transparent var(--border-width-thin);
}

.program-playlist .box.playlist > .header {
  border-left: solid transparent var(--border-width-thin);
  order: 2;
}

.program-playlist .box.playlist.expanded > .header {
  border-left-color: var(--color-black)
}

.program-playlist .box.program.expanded > .header {
  border-right-color: var(--color-black);
}

.program-playlist .box > .header > .title {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  padding: 1.5rem 0.2rem 0.5rem 0.2rem;
}

.program-playlist .box > .expand-list {
  max-width: 0;
  height: 250px;
  margin-bottom: 0.1rem;
  background-color: var(--color-white)
}

.program-playlist .box.expanded > .expand-list {
  max-width: var(--expandlist-width);
}

.program-playlist .box > .expand-list > .prg-wrapper {
  width: calc(var(--expandlist-width) - 0.5rem - 0.5rem);
}

@media (max-width: 992px), (max-height: 600px) {
  .program-playlist {
    transform: translate(-50%, 0);
  }

  .program-playlist .box {
    display: block;
    top: 50%;
    left: initial;
    right: 0;
  }

  .program-playlist .box.expanded {
    z-index: 7;
  }

  .program-playlist .box.playlist {
    transform: translate(100%, 0%);
  }

  .program-playlist .box.program {
    border-right-width: 0.5px;
    transform: translate(0);
    border-bottom: solid var(--color-black) var(--border-width-thin);
  }

  .program-playlist .box > .header {
    display: flex;
    border-right: none;
    width: initial;
  }

  .program-playlist .box.program > .header {
    border-right: none;
  }

  .program-playlist .box.playlist > .header {
    border-left: none;
  }

  .program-playlist .box > .header > .title {
    padding: 0;
    margin-left: 0.5rem;
    writing-mode: initial;
    transform: rotate(0deg);
  }

  .program-playlist .box > .header > .arrow {
    order: 2;
    border-bottom: none;
  }

  .program-playlist .box > .expand-list {
    left: calc(var(--border-width-thin) * -1);
    top: 30px;
    position: absolute;
    height: 280px;
    z-index: 18;
    background-color: var(--color-random2);
    transition: transform ease var(--transition-time1);
    transform: scaleY(0);
    transform-origin: top;
    border: var(--border-width-thin) var(--color-black) solid;
    padding-bottom: 0.3rem;
  }

  .program-playlist .box > .expand-list, .program-playlist .box.expanded > .expand-list {
    max-width: initial;
    width: 100vw;
  }

  .program-playlist .box.playlist > .expand-list {
    transform: scaleY(0) translateX(-50%);
  }

  .program-playlist .box.expanded > .expand-list {
    transform: scaleY(1);
  }

  .program-playlist .box.expanded.playlist > .expand-list {
    transform: scaleY(1) translateX(-50%);
  }

  .program-playlist .box > .expand-list > .prg-wrapper {
    width: calc(100% - 1rem);
  }
}

</style>
