<template>
  <div :class="containerClass">
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 25.8 20.9" style="enable-background:new 0 0 25.8 20.9;" xml:space="preserve">
      <g>
	<defs>
		<rect id="SVGID_1_" width="25.8" height="20.9"/>
	</defs>
        <clipPath id="SVGID_00000091004277609915634280000012249380400978290576_">
		<use xlink:href="#SVGID_1_" style="overflow:visible;"/>
	</clipPath>
        <path style="clip-path:url(#SVGID_00000091004277609915634280000012249380400978290576_);" d="M7.7,8.45c0.19,0,0.38,0,0.56,0
		c5.62,0,11.24,0,16.86,0c0.67,0,0.67,0,0.67,0.68c0,0.91-0.02,1.83,0.01,2.74c0.02,0.47-0.13,0.59-0.59,0.59
		c-5.63-0.02-11.27-0.01-16.9-0.01c-0.2,0-0.39,0-0.72,0c0.16,0.2,0.24,0.32,0.33,0.41c1.67,1.67,3.33,3.35,5.01,5.01
		c0.24,0.24,0.3,0.4,0.03,0.66c-0.75,0.72-1.48,1.45-2.2,2.19c-0.24,0.25-0.4,0.23-0.64,0c-3.31-3.32-6.62-6.64-9.94-9.95
		c-0.24-0.24-0.23-0.4,0-0.64C3.5,6.82,6.82,3.5,10.13,0.18c0.24-0.25,0.4-0.23,0.63,0c0.71,0.73,1.43,1.46,2.16,2.16
		c0.28,0.26,0.28,0.43,0,0.69c-1.66,1.64-3.3,3.29-4.95,4.94C7.87,8.09,7.76,8.21,7.66,8.33C7.67,8.37,7.69,8.41,7.7,8.45"/>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: "SvgArrow",
  props: {
    containerClass: String
  }
}
</script>

<style scoped>
svg {
  width: 1rem;
  transition: transform ease var(--transition-time1);
}
#player .box.program > .header > .arrow svg,
#player .box.playlist > .header > .arrow svg {
  transform: rotate(270deg);
}

#player .box.program.expanded > .header > .arrow svg,
#player .box.program.loading > .header > .arrow svg,
#player .box.playlist.expanded > .header > .arrow svg,
#player .box.playlist.loading > .header > .arrow svg {
  transform: rotate(90deg);
}
#player-landing .box.program > .header > .arrow svg,
#player-landing .box.playlist.expanded > .header > .arrow svg,
#player-landing .box.playlist.loading > .header > .arrow svg  {
  transform: rotate(0deg);
}
#player-landing .box.playlist > .header > .arrow svg,
#player-landing .box.program.expanded > .header > .arrow svg,
#player-landing .box.program.loading > .header > .arrow svg {
  transform: rotate(180deg);
}
@media (max-width: 992px), (max-height: 600px) {
  #player-landing .box.program > .header > .arrow svg,
  #player-landing .box.playlist > .header > .arrow svg {
    transform: rotate(270deg);
  }
  #player-landing .box.program.expanded > .header > .arrow svg,
  #player-landing .box.program.loading > .header > .arrow svg,
  #player-landing .box.playlist.expanded > .header > .arrow svg,
  #player-landing .box.playlist.loading > .header > .arrow svg {
    transform: rotate(90deg);
  }
}
.st0 {
  clip-path: url(#SVGID_00000138538622835097567090000008108796661421062838_);
}
</style>