import axios from "axios";

let wpApiUrlBase = 'https://backend.kanalk.ch/wp-json/wp/v2/'

/**
 * Page Data By Path/Slug (via Custom Endpoint)
 * @param wpPath
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchPageData(wpPath) {
    return axios
        .get(wpApiUrlBase + 'page?path=' + wpPath)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Page and Subpages Data By Path/Slug (via Custom Endpoint)
 * @param wpPath
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchPageSubpagesData(wpPath) {
    return axios
        .get(wpApiUrlBase + 'pagesubpages?path=' + wpPath)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Post Data By Slug (via Custom Endpoint)
 * @param wpSlug
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchPostData(wpSlug) {
        return axios
        .get(wpApiUrlBase + 'post?slug=' + wpSlug)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Fetch Posts
 * @param wpCat
 * @param numberposts
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchPosts(wpCat, numberposts = 50) {
    return axios
        .get(wpApiUrlBase + 'posts?cat=' + wpCat + '&numberposts=' + numberposts)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Fetch Posts with Pagination
 * @param wpCat
 * @param numberposts
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchPostsPaginated(wpCat, numberposts = 24, page = 1) {
    return axios
        .get(wpApiUrlBase + 'posts-paginated?cat=' + wpCat + '&numberposts=' + numberposts + '&page=' + page)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Kommunity Posts (=People) By Category (via Custom Endpoint)
 * @param wpCat
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchKommunityPostsByCategory( wpCat ) {
    return axios
        .get(wpApiUrlBase + 'kommunitypeople?cat=' + wpCat)
        .then(res => res.data)
}

/**
 * Sendungen Posts (=alle Sendungen) (via Custom Endpoint)
 * @param wpCat
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchSendungPosts(  ) {
    return axios
        .get(wpApiUrlBase + 'sendungen')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}

/**
 * Sendung Data By Slug via Custom Endpoint
 * @param wpSlug
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchSendungData( wpSlug ) {
    return axios
        .get(wpApiUrlBase + 'sendung?slug=' + wpSlug)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Sendung Slug by Pluxbox ID (via Custom Endpoint),
 * to access Sendung Page via /sendung/[pluxbox_id]
 * @param pbId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchSendungSlugByPluxboxId(pbId) {
    return axios
        .get(wpApiUrlBase + 'sendungslugbypluxboxid?pbId=' + pbId)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Hello Posts (=alle Hello Links) (via Custom Endpoint)
 * @param wpCat
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchHelloPosts(  ) {
    return axios
        .get(wpApiUrlBase + 'hellolinks')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}

/**
 * Get Widgets (via Custom Endpoint)
 * @param widgetId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchWidgetContent(widgetId) {
    return axios
        .get(wpApiUrlBase + 'widget?widgetid=' + widgetId)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}

/**
 * Get Menu Items Primray (via Custom Endpoint)
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchMenuItemsPrimary() {
    return axios
        .get(wpApiUrlBase + 'menu-primary')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}
/**
 * Get Menu Items Secondary 1 (via Custom Endpoint)
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchMenuItemsSecondaryOne() {
    return axios
        .get(wpApiUrlBase + 'menu-secondary-one')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}
/**
 * Get Menu Items Secondary 2 (via Custom Endpoint)
 * @returns {Promise<AxiosResponse<any>>}
 */
export function wpFetchMenuItemsSecondaryTwo() {
    return axios
        .get(wpApiUrlBase + 'menu-secondary-two')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}
