<template>
  <div :class="containerClass">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 28.06 28.61" style="enable-background:new 0 0 28.06 28.61;" xml:space="preserve">
      <rect class="smc0" width="28.06" height="28.61"/>
      <rect class="smc1" width="28.06" height="28.61"/>
      <path class="smc2" d="M19.67,13.18H8.38v2.25h11.29V13.18z M15.15,8.66H12.9v11.29h2.25V8.66z"/>
</svg>
  </div>
</template>

<script>
export default {
  name: "SvgSubmenuClosed",
  props: {
    containerClass: String
  }
}
</script>

<style scoped>
.smc0{fill:#FFFFFF;}
.smc1{fill:none;stroke:#010101;stroke-width:1.417;}
.smc2{fill:#010101;}
</style>