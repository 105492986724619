<template>
  <div class="content">
    <ErrorPageNotFound text="Diese Seite existiert nicht" v-if="error404"></ErrorPageNotFound>

    <!-- Scroll Sub Nav -->
    <Transition name="image">
      <div id="subnav" class="scroll-subnav" v-if="showSubnav" :class="[scrollSubnavOpened ? 'opened':'closed']">
        <div>
          <a @click.prevent="toggleScrollSubnav" class="d-block d-lg-none subnav-toggle" v-bind:href="this.$route.path">
            <span>Untermenu {{ scrollSubnavOpened ? 'schliessen' : 'öffnen' }}</span><i class="fa fa-angle-down"
                                                                                        aria-hidden="true"></i></a>
<!--          <a v-for="(subpage, s) in subpages" :key="s"-->
<!--             @click.prevent="clickScrollSubnavLink(s)"-->
<!--             v-bind:href="this.$route.path + '/' + s" v-html="subpage.title"/>-->

          <a v-for="(subpage, s) in subpages" :key="s"
             @click.prevent="clickScrollSubnavLink(s)"
             v-bind:href="scrollSubnavHref + s" v-html="subpage.title"/>

        </div>
      </div>
    </Transition>

    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div class="row" v-if="pageContent">
          <div class="col-lg-9 col-xl-12">
            <div class="text" v-html="pageContent"/>
          </div>
        </div>
      </Transition>
      <Transition name="content">
        <div v-if="subpages" class="row">
          <div v-for="(subpage, s) in subpages" :key="s" v-bind:id="s" class="mt-3 col-lg-12">
            <h3 v-html="subpage.title"/>
            <div class="text" v-html="subpage.content"/>
          </div>
        </div>
      </Transition>
    </div>

  </div>
</template>

<script>
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {wpFetchPageSubpagesData} from "@/api/wordpress";
import {globalDashPath} from "@/main";

export default {
  name: "PageSubpagesView",
  components: {ErrorPageNotFound},
  data() {
    return {
      error404: false,
      pageTitle: null,
      pageContent: null,
      subpages: null,
      showSubnav: false,
      scrollSubnavOpened: false
    }
  },
  methods: {
    toggleScrollSubnav() {
      this.scrollSubnavOpened = !this.scrollSubnavOpened;
    },
    closeScrollSubnav() {
      this.scrollSubnavOpened = false;
    },
    clickScrollSubnavLink(hash) {
      this.closeScrollSubnav();
      this.$router.push({hash: '#' + hash})
    }
  },
  computed: {
    scrollSubnavHref() {
      return globalDashPath(this.$route.path);
  }
  },
  watch: {},
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageSubpagesData(this.$route.path).then(result => {
      /*check 404*/
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      /*Fill Data*/
      this.pageTitle = result.title;
      this.pageContent = result.content;
      this.subpages = result.subpages
      this.showSubnav = true;
      /*Scroll To Subpage/Section Content after it's loaded if there is Hash*/
      if (this.$route.hash) {
        this.$router.push({hash: this.$route.hash})
      }
      this.$store.state.loadingProgressbar.finish()
    })
  },
  mounted() {
  }
}
</script>

<style scoped>
.scroll-subnav {
  position: sticky;
  top: calc(2 * var(--outer-sides-margin) + var(--header-height));
  left: 100%;
  display: flex;
  justify-content: right;
  width: 0;
  z-index: 1;
}

.scroll-subnav > div {
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
}

.scroll-subnav > div > a {
  display: block;
  border: var(--color-black) solid var(--border-width-thin);
  border-bottom: none;
  padding: 0.2rem 5rem 0.2rem 0.8rem;
  transition: all ease var(--transition-time1);
  text-decoration: none;
  color: var(--color-black);
  font-size: .65rem;
  line-height: 1;
  white-space: nowrap;
}

.scroll-subnav > div > a:hover, .scroll-subnav.opened > div > a.subnav-toggle {
  background-color: var(--color-white);
}

.scroll-subnav > div > a:last-child {
  border-bottom: var(--color-black) solid var(--border-width-thin);
}

@media (max-width: 992px) {
  #content-wrapper:not(.landing) .content {
    margin-top: calc(var(--content-top-margin) + var(--header-height) + var(--song-marquee-height) + var(--song-marquee-height));
  }

  .scroll-subnav {
    position: fixed;
    left: 0;
    display: block;
    width: 100%;
  }

  .scroll-subnav > div {
    position: absolute;
    width: 100%;
  }

  .scroll-subnav > div > a {
    padding: 0.2rem 0.5rem;
    padding: 0.5rem 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .scroll-subnav > div > a.subnav-toggle {
    cursor: pointer;
  }

  .scroll-subnav.closed > div > a.subnav-toggle {
    border-bottom: var(--color-black) solid var(--border-width-thin);
  }

  .scroll-subnav > div > a:not(.subnav-toggle) {
    transition: all ease var(--transition-time1);
    transform: scaleY(0);
    transform-origin: top;
  }

  .scroll-subnav.opened > div > a:not(.subnav-toggle) {
    transform: scaleY(1);
  }

  .scroll-subnav > div > a > i {
    float: right;
    margin-top: -2px;
    transition: all ease var(--transition-time1);
    font-size: 0.8rem;
  }

  /*.scroll-subnav > div > a, .scroll-subnav > div > a > span {*/
  /*  font-weight: 600;*/
  /*  text-transform: uppercase;*/
  /*}*/
}

</style>
