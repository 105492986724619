<template>
  <div class="content">
    <ErrorPageNotFound text="Diese Seite existiert nicht" v-if="error404"></ErrorPageNotFound>
    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <form v-if="pageTitle" @submit.prevent="searchEnter" class="search form" :class="filterStatus">
          <input v-model="searchFilter" class="" type="text" v-bind:placeholder="this.searchFormPlaceholer">
          <div @click.prevent="resetSearchFilter()" class="reset"><i class="fa fa-close"></i></div>
        </form>
      </Transition>
      <div class="row gut-10px">
        <TransitionGroup name="tiles" v-if="showSearchResults || searchFilter !== ''">
          <div v-if="sendungen.length >0 && sendungenFiltered.length <= 0" class="text mt-1">Für den Suchbegriff
            "{{ searchFilter }}" wurde keine Sendung gefunden.
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" v-for="(sendung, i) in sendungenSorted" :key="i">
            <router-link v-bind:to="'/' + this.sendungType + '/' + sendung.slug" class="post-link">
              <div v-if="sendung.image && sendung.overview_showimage != 'icon'" class="image-wrapper canvas can-square">
                <div class="bg image " v-bind:style="{ backgroundImage: 'url(' + sendung.image + ')' }"/>
                <div class="bg image color-layer"/>
                <div class="hover-layer"/>
              </div>
              <div v-else class="image-wrapper default canvas can-square ">
                <div class="bg image" v-if="sendungType == 'sendung'"
                     v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_gelb.png`) + ')' }"/>
                <div class="bg image" v-else-if="sendungType == 'podcast'"
                     v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_green.png`) + ')' }"/>
                <div class="bg image" v-if="sendungType == 'sendung'"
                     v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_gelb_inverted.png`) + ')' }"/>
                <div class="bg image" v-if="sendungType == 'podcast'"
                     v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_green_inverted.png`) + ')' }"/>
                <div class="hover-layer"/>
              </div>
              <h3 class="mt-3px mt-sm-10px" v-html="sendung.title"/>
            </router-link>
          </div>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {wpFetchPageData, wpFetchSendungPosts} from "@/api/wordpress";
import {globalImagePreload, globalNormalizeString} from "@/main";

export default {
  name: "SendungenView",
  components: {ErrorPageNotFound},
  props: {
    showSearchResults: Boolean,
    sendungType: String
  },
  data() {
    return {
      error404: false,
      searchFilter: '',
      pageTitle: null,
      pageContent: null,
      sendungen: [],
    }
  },
  computed: {
    sendungenByType() {
      return this.sendungen.filter(sendung => {
        if (sendung.type !== null) {
          return sendung.type.includes(this.sendungType)
        }
      })
    },
    sendungenFiltered() {
      let sendungen = this.sendungenByType;
      let searchFilter = globalNormalizeString(this.searchFilter);
      return sendungen.filter(sendung => {
        return globalNormalizeString(sendung.title).includes(searchFilter)
      })
    },
    sendungenSorted() {
      function sortAsc(a, b) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
        return 0;
      }
      let sendungen = this.sendungenFiltered;
      return sendungen.sort(sortAsc)
    },
    filterStatus() {
      return this.searchFilter == '' ? 'reset-button-off' : 'reset-button-on';
    },
    searchFormPlaceholer() {
      let placeholder = "Suche nach ";
      if (this.sendungType == 'sendung') {
        placeholder += 'Sendungen'
      } else if (this.sendungType == 'podcast') {
        placeholder += 'Podcasts'
      }
      return placeholder
    }
  },

  methods: {
    resetSearchFilter() {
      this.searchFilter = '';
      window.history.replaceState(history.state, '', this.$route.path);
    },
    searchEnter() {
      window.history.replaceState(history.state, '', '?s=' + this.searchFilter);
    }
  },
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      // this.wpPageData = result
      this.pageTitle = result.title;
      this.pageContent = result.content;
      //Cache all Sendungen via Vuex Store
      if (this.$store.getters.getPreloadSendungen) { //Sendungen are already preloaded
        this.sendungen = this.$store.getters.getPreloadSendungen
        this.$store.state.loadingProgressbar.finish()
      } else { //Load all Sendungen
        wpFetchSendungPosts().then(sendungen => {
          this.$store.commit('setPreloadSendungen', sendungen)
          for (let i = 0; i < sendungen.length; i++) { // Try to Preload Images
            globalImagePreload(sendungen[i].image).then(() => {
              this.sendungen.push(sendungen[i])
            })
          }
          // this.sendungen = sendungen
          this.$store.state.loadingProgressbar.finish()
        });
      }
      //Search Init via URL
      if (this.$route.query.s !== undefined) {
        this.searchFilter = this.$route.query.s
      }
    })
  },
}
</script>

<style scoped>


/*a.post-link:hover h3 {*/
/*  color: red;*/
/*}*/

.search {
  display: flex;
  overflow: hidden;
}

@media (max-width: 575px) {
  h3 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
</style>
