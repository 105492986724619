<template>
  <div class="content">
    <ErrorPageNotFound text="Diese Seite existiert nicht" v-if="error404"></ErrorPageNotFound>
    <div v-else class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div v-if="pageContent" class="mb-1" v-html="pageContent"/>
      </Transition>
      <div class="row gut-10px mt-1" v-if="hasPosts">
        <TransitionGroup name="tiles">
          <template v-for="(postsPage, p) in posts" :key="p">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" v-for="(post, i) in postsPage" :key="i">
              <router-link v-bind:to="postLinkUrl(post)" class="post-link">
                <div v-if="post.image" class="image-wrapper canvas can-square">
                  <div class="bg image " v-bind:style="{ backgroundImage: 'url(' + post.image + ')' }"/>
                  <div class="bg image color-layer"/>
                  <div class="hover-layer"/>
                </div>
                <div v-else class="image-wrapper default canvas can-square ">
                  <div class="bg image"
                       v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_schwarz.png`) + ')' }"/>
                  <div class="bg image"
                       v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/Kanal_K_Logo_RGB_Webseite2020_schwarz_inverted.png`) + ')' }"/>
                  <div class="hover-layer"/>
                </div>
                <h3 class="mt-10px" v-html="post.title"/>
              </router-link>
              <div class="mt&#45;&#45;15px mb-10px" v-if="post.episode_sendung.sendung_slug">
                <router-link v-if="this.postCat == 'nachhoeren' || this.postCat == 'podcasts' || this.postCat == 'news'"
                             v-bind:to="categoryLinkUrl(post)" class="readmore-link "
                             v-html="post.episode_sendung.sendung_title"></router-link>
              </div>
            </div>
          </template>
        </TransitionGroup>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <Transition name="tiles">
          <div class="w-50perc">
          <button class="btn large btn-neutral btn-ctad w-50perc" v-if="hasPosts && this.postPaginationButton" @click="loadMorePosts">Mehr laden...</button>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script>
import {wpFetchPageData, wpFetchPostsPaginated} from "@/api/wordpress";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {globalDashPath, globalRelativizeHref} from "@/main";

export default {
  name: "PostCategoryView",
  components: {ErrorPageNotFound},
  props: ['postCat', 'postCatSlug'],
  data() {
    return {
      error404: false,
      pageTitle: null,
      pageContent: null,
      postCagegories: {
        'news': 'news',
        'nachhoeren': 'all-podcast-episodes',
        'podcasts': 'all-podcast-episodes',
        'musik': 'musik',
        'f-tracks': 'f-tracks',
        'k-tracks-magazin': 'k-tracks',
        'new-entries': 'new-entries'
      },
      posts: [],
      postsPagination: [],
      postPaginationButton: true
    }
  },
  computed: {
    hasPosts() {
      return this.posts.length == 0 ? false : true
    },
    getPostsPaginationNextpage() {
      return this.postsPagination.nextpage ? this.postsPagination.nextpage : false;
    },
    getPostsPaginationPostperpage() {
      return this.postsPagination.posts_per_page ? this.postsPagination.posts_per_page : false;
    }
  },
  methods: {
    postLinkUrl(post) {
      let postEpisodeSendungSlug = post.episode_sendung.sendung_slug;
      if(this.postCat === 'podcasts' && typeof this.postCatSlug !== 'undefined') {
        if (postEpisodeSendungSlug === null) { //e.g Podcast without Category
          return '/nachhoeren/' + post.slug;
        }
        return '/' + this.postCatSlug + '/' + postEpisodeSendungSlug + '/' + post.slug; //e.g. Podcast with Categories
      }
      else if(typeof this.postCatSlug === 'undefined') { //e.g. F-Tracks etc.
        return globalDashPath(globalRelativizeHref(window.location.href)) + post.slug;
      }
      return '/' + this.postCatSlug + '/' + post.slug; //e.g. News Page
    },
    categoryLinkUrl(post) {
      return '/' + this.postCatSlug + '/' + post.episode_sendung.sendung_slug;
    },
    loadMorePosts() {
      if(this.$progress.state().active) {
        this.$store.state.loadingProgressbar = this.$progress.start();
        this.$store.state.loadingProgressbar.finish()
      }
      this.$store.state.loadingProgressbar = this.$progress.start();
      wpFetchPostsPaginated(this.postCagegories[this.postCat], this.getPostsPaginationPostperpage, this.getPostsPaginationNextpage).then(result => {
        if(result !== false) {
          this.postsPagination = result.pagination
          this.posts[result.pagination.index] = result.posts
        }
        else {
          this.postPaginationButton = false;
        }
        this.$store.state.loadingProgressbar.finish()
      });
    }
  },
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if (result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      this.pageTitle = result.title;
      this.pageContent = result.content;
      wpFetchPostsPaginated(this.postCagegories[this.postCat], 24, 1).then(result => {
        if(result !== false) {
          this.postsPagination = result.pagination
          this.posts[result.pagination.index] = result.posts
        }
        else {
          this.postPaginationButton = false;
        }
        this.$store.state.loadingProgressbar.finish()
      });
    })
  },
}
</script>

<style scoped>

</style>
