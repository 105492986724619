<template>
  <div id="popup" @click.prevent="closePopup">
    <div class="container-fluid container-inner container-max">
      <div class="popup-wrapper row justify-content-center">
        <div class="col-md-6" @click.stop="" >
          <div class="popup-header">
            <div class="popup-close" @click.prevent="closePopup"><i class="fa fa-close"></i></div>
          </div>
          <div class="popup-content text" v-html="this.$store.getters.getPopupContentByName(this.popupName)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PartPopup",
  components: {},
  props: {
    class: String,
    id: String,
    widgetId: String,
    popupName: String,
  },
  data() {
    return {
      active: false,
      oldContent: null
    }
  },
  computed: {},
  methods: {
    closePopup() {
      // console.log(e.target);
      this.$store.commit('setPopupDisplay', {name: this.popupName, display: false})
    }
  },
  setup() {

  },
  async created() {
  }
}
</script>

<style scoped>
#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 104vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black-op7);
  transition: all ease var(--transition-time1);
  overflow: hidden;
  z-index: 12;
}

#popup .popup-wrapper {
    transform: translateY(-2vh);
}

#popup .popup-wrapper .popup-content {
  padding: var(--inline-sides-margin);
  background-color: var(--color-random1);
  text-align: justify;
}

#popup .popup-wrapper .popup-header {
  background-color: var(--color-random2);
  padding: var(--box-margin) var(--inline-sides-margin);
  text-align: right;
}

#popup .popup-wrapper .popup-header .popup-close {
  cursor: pointer;
  color: var(--color-black);
  display: inline-block;
  transition: all ease var(--transition-time1);
}

#popup .popup-wrapper .popup-header .popup-close:hover {
  color: var(--color-random1);
}
</style>
