import axios from "axios";

let mailchimpApiUrlBase = 'https://api.kanalk.ch/mailchimp/';

/**
 * Subscribe New Newsletter Abos via Mailchimp Api
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
export function mailchimpSubmitSubscriptionForm(email) {
    return axios
        .get(mailchimpApiUrlBase + '?email=' + email)
        .then(function (res) {
            if (typeof res !== "undefined") {
                return res.data
            }
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });
}