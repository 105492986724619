<template>
  <footer>
    <div class="container-fluid container-inner container-max">
      <div class="row info-widget medium-small">
        <div class="col-lg-3 col-sm-4">
          <h4>Kontakt</h4>
          <WidgetContact container-class="text"></WidgetContact>
          <router-link to="/newsletter">Newsletter</router-link>
        </div>
        <div class="col-lg-3 col-sm-4 mt-1 mt-sm-0">
          <h4 class="d-none d-sm-block">&nbsp;</h4>
          <MenuSecondary1 container-class="menu-secondary"></MenuSecondary1>
          <MenuSocials container-class="menu-social mt-1"></MenuSocials>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import MenuSocials from "@/components/MenuSocials";
import MenuSecondary1 from "@/components/MenuSecondary1";
import WidgetContact from "@/components/widgets/WidgetContact";

export default {
  name: "PartFooter",
  components: {
    MenuSecondary1, MenuSocials, WidgetContact
  }
}
</script>

<style scoped>
footer {
  background-color: var(--color-black);
  padding: 1.2rem 0;
  margin-top: 5rem;
}


</style>