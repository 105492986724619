<template>
  <div :class="containerClass">
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 223.65 262.13" style="enable-background:new 0 0 223.65 262.13;" xml:space="preserve">
    <g>
	<path class="playbtn0" d="M0.97,260.44V1.69C74.6,44.83,147.39,87.48,221.73,131.04C147.75,174.4,75.01,217.04,0.97,260.44"/>
      <path class="playbtn1" d="M0.97,260.44V1.69C74.6,44.83,147.39,87.48,221.73,131.04C147.75,174.4,75.01,217.04,0.97,260.44z"/>
      <path class="playbtn2" d="M15.16,232.08V30.05C73.5,63.74,131.18,97.03,190.1,131.04C131.47,164.9,73.83,198.19,15.16,232.08"/>
      <path class="playbtn1" d="M15.16,232.08V30.05C73.5,63.74,131.18,97.03,190.1,131.04C131.47,164.9,73.83,198.19,15.16,232.08z"/>
      <path d="M31.92,202.44V59.68c42.89,23.8,85.29,47.33,128.59,71.37C117.42,154.98,75.05,178.5,31.92,202.44"/>
      <path class="playbtn1" d="M31.92,202.44V59.68c42.89,23.8,85.29,47.33,128.59,71.37C117.42,154.98,75.05,178.5,31.92,202.44z"/>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: "SvgPlayerButtonPlay",
  props: {
    containerClass: String
  }
}
</script>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
.playbtn0{fill:#FFFFFF;}
.playbtn1{fill:none;stroke:#000000;stroke-width:1.935;}
.playbtn2{
  fill: var(--color-random1);
}
</style>