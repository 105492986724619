<template>
  <ul>
    <li v-for="(cookie, c) in this.cookies.keys()" :key="c" v-html="cookie"/>
  </ul>
</template>
<script>
import {useCookies} from "vue3-cookies";

export default {
  //TODO all of it

  name: "SettingCookies",
  setup() {
    // console.log(cookies.remove('_ga'));
    // console.log(cookies.remove('_ga_ZS86KS94M7'));
    const { cookies } = useCookies();
    // cookies.remove('_ga_ZS86KS94M7');
    // cookies.remove('_ga');
    console.log(cookies.get);
    return { cookies };
  },
}
</script>
<style scoped>

</style>
