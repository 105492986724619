import axios from "axios";

let pbApiUrlBase = 'https://api.kanalk.ch/pluxbox/';
// let pbApiUrlBase = 'https://meta.kanalk.ch/api/apikanalkchtemp/pluxbox/'

/**
 * Show Description via Show ID
 * @param pbShowId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchShowDescription(pbShowId) {
    return axios
        .get(pbApiUrlBase + '?q=showDetails&show=' + pbShowId)
        .then(res => {
            if (typeof res.data.description !== "undefined") {
                return res.data.description
            }
            return false;
        });
}

/**
 * Show Moderation via Show ID
 * @param pbShowId
 * @returns {Promise<string>}
 */
export function pbFetchShowModi(pbShowId) {
    return axios
        .get(pbApiUrlBase + '?q=showModi&show=' + pbShowId)
        .then(res => {
            let modi = ''
            if (typeof res.data.results !== "undefined") {
                for (let i = 0; i < res.data.results.length; i++) {
                    if (i > 0) {
                        modi += '<br>'
                    }
                    modi += res.data.results[i].name
                }
            }
            return modi;
        });
}

/**
 * Nächste Sendungen/Episoden Ausstrahlungen via Show ID
 * @param pbShowId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchShowNextBroadcasts(pbShowId) {
    return axios
        .get(pbApiUrlBase + '?q=showNextBroadcasts&show=' + pbShowId)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Current Program
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchProgramCurrent() {
    return axios
        .get(pbApiUrlBase + '?q=programCurrent')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Next Program
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchProgramNext() {
    return axios
        .get(pbApiUrlBase + '?q=programNext')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Todays Program Full
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchProgramToday() {
    return axios
        .get(pbApiUrlBase + '?q=programToday&days=0')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Full Daily Program By Date
 * @param date
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pbFetchProgramDay(date) {
    return axios
        .get(pbApiUrlBase + '?q=programDay&date=' + date)
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}