<template>
  <template v-if="this.getPosts">
    <section v-bind:id="sectionId" :class="sectionClass" class="post-slider">
      <div class="section-inner">
        <div class="section-title">
          <div class="container-fluid container-center">
            <h2 class="neutral">{{ sectionTitle }}</h2>
          </div>
        </div>
        <Transition name="content">
          <div class="section-content" v-if="getPosts">
            <div class="carousel">
              <flickity v-if="getPosts" ref="slider" :options="flickityOptions" class="main-carousel">
                <div v-for="(post, p) in getPosts" class="carousel-cell" :key="p">
                  <router-link v-bind:to="postLinkUrl(post)">
                    <div v-if="post.image" class="bg canvas can-square post-image"
                         v-bind:style="{ backgroundImage: 'url(' + post.image + ')' }">
                      <div class="hover-layer"></div>
                    </div>
                  </router-link>
                  <div class="post-date" v-html="post.date"/>
                  <div class="mt--10px" v-if="showSendung && post.episode_sendung.sendung_slug">
                    <router-link v-bind:to="'/' + postCatSlug + '/' + post.episode_sendung.sendung_slug" class="readmore-link mt-10"
                                 v-html="post.episode_sendung.sendung_title"></router-link>
                  </div>
                  <h3 class="post-title" v-html="post.title"/>
                  <router-link v-bind:to="postLinkUrl(post)" class="readmore-link"
                               v-html="postLinkText"></router-link>
                </div>
              </flickity>
              <template v-if="this.getPostCount > 3 || this.mq.mdMinus">
              <button class="flickity-button flickity-prev-next-button previous" @click="sliderPrevious()">
                <SvgCarouselButtonPrevious/>
              </button>
              <button class="flickity-button flickity-prev-next-button next" @click="sliderNext()">
                <SvgCarouselButtonNext/>
              </button>
              </template>
              <router-link v-bind:to="buttonLink">
                <button v-if="buttonText" class="btn btn-neutral btn-cta" v-html="buttonText"/>
                <button v-else class="btn btn-neutral btn-cta">Alles ansehen</button>
              </router-link>
            </div>
          </div>
        </Transition>
      </div>
    </section>
  </template>
</template>

<script>
import Flickity from 'vue-flickity'
import SvgCarouselButtonNext from "@/components/svg/SvgCarouselButtonNext";
import SvgCarouselButtonPrevious from "@/components/svg/SvgCarouselButtonPrevious";
import {useProgress} from "@marcoschulte/vue3-progress";
import {wpFetchPosts} from "@/api/wordpress";
import {globalDashPath, globalRelativizeHref} from "@/main";

export default {
  name: "SectionPostSlider",
  components: {
    SvgCarouselButtonPrevious,
    SvgCarouselButtonNext,
    Flickity
  },
  inject: ["mq"],
  props: {
    sectionId: String,
    sectionClass: String,
    sectionTitle: String,
    buttonText: String,
    buttonLink: String,
    postCat: String,
    postCatSlug: String,
    postLinkText: String,
    postsCategory: String,
    maxPosts: String,
    minPosts: String,
    storeCache: String,
    showSendung: Boolean
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        cellAlign: 'left',
        autoPlay: false,
        groupCells: true,
        //adaptiveHeight: this.mq.xs,
        adaptiveHeight: true,
      },
      posts: false,
    }
  },
  computed: {
    getMaxPosts() {
      return this.maxPosts ? this.maxPosts : 100;
    },
    getMinPosts() {
      return this.minPosts ? this.minPosts : 3;
    },
    getPostCount() {
      return this.posts.length
    },
    getPosts() {
      if (this.getMinPosts && this.getPostCount >= this.getMinPosts) {
        return this.posts ? this.posts.slice(0, this.getMaxPosts) : false
      } else {
        return false
      }
    }
  },
  methods: {
    postLinkUrl(post) {
      let postEpisodeSendungSlug = post.episode_sendung.sendung_slug;
      if(this.postCat === 'podcasts' && typeof this.postCatSlug !== 'undefined') {
        if (postEpisodeSendungSlug === null) { //e.g Podcast without Category
          return '/nachhoeren/' + post.slug;
        }
        return '/' + this.postCatSlug + '/' + postEpisodeSendungSlug + '/' + post.slug; //e.g. Podcast with Categories
      }
      else if(typeof this.postCatSlug === 'undefined') { //e.g. F-Tracks etc.
        return globalDashPath(globalRelativizeHref(window.location.href)) + post.slug;
      }
      return '/' + this.postCatSlug + '/' + post.slug; //e.g. News Page
    },
    sliderNext() {
      this.$refs.slider.next();
    },
    sliderPrevious() {
      this.$refs.slider.previous();
    },
  },
  async created() {
    this.$store.state.loadingProgressbarPostSlider[this.sectionId] = useProgress().start();
    //Cache all Slider Posts via Vuex Store
    if (this.$store.getters.getPreloadPostSlider[this.sectionId]) { //Take from Cache
      this.posts = this.$store.getters.getPreloadPostSlider[this.sectionId]
      this.$store.state.loadingProgressbarPostSlider[this.sectionId].finish()
    } else { //Load New and fill Cache
      wpFetchPosts(this.postsCategory, 10).then(result => {
        this.$store.commit('setPreloadPostSlider', {'id': this.sectionId, 'result': result.posts})
        this.posts = result.posts
        this.$store.state.loadingProgressbarPostSlider[this.sectionId].finish()
      });
    }
  }
}
</script>

<style scoped>
.btn-cta {
  position: absolute;
  right: var(--outer-sides-margin);
  transition: all ease var(--transition-time1)
}

.btn-cta:hover {
  color: var(--color-random2);
  border-color: var(--color-random2)
}

.btn-cta:focus {
  outline: 0;
  box-shadow: none;
}

/*=== Carousel === */
.carousel {
  padding: 0 var(--center-sides-margin);
}

.flickity-prev-next-button {
  height: 60px;
  border-radius: 0;
}

.flickity-button {
  background: var(--color-white);
  border: solid var(--color-black) var(--border-width-thin);
  color: #333;
  transition: all ease var(--transition-time1);
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:focus, .flickity-button:hover {
  outline: none;
  box-shadow: 0 0 0 3px var(--color-random2);

  color: var(--color-random2);
  border-color: var(--color-random2)
}

.flickity-button:focus {
  box-shadow: 0 0 0 5px var(--color-random2);
}

.flickity-button:disabled {
  display: none;
}

.flickity-prev-next-button.next {
  right: 0;
  transform: translate(0%, -50%);
}

.flickity-prev-next-button.previous {
  left: 0;
  transform: translate(0%, -50%);
}

.carousel .flickity-viewport {
  margin-left: -1rem;
  margin-right: -1rem;
}

.carousel-cell {
  width: 33.3333%;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.carousel-cell:first-child {
  margin-leftd: -2rem;
}

.post-image {
  margin-bottom: 0.5rem;
}

@media (max-width: 992px) {
  .carousel {
    padding-left: 10px;
    padding-right: 10px;
  }

  .flickity-prev-next-button {
    height: 45px;
    width: 45px;
    /*border-radius: 0;*/
  }

  .carousel .flickity-viewport {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .carousel-cell {
    width: 50%;
    padding: 0 0.5rem;
  }
}

@media (max-width: 540px) {
  .carousel-cell {
    width: 100%;
    padding: 0 0;
  }
}
</style>