<template>
  <div class="content"></div>
</template>

<script>
import {wpFetchSendungSlugByPluxboxId} from "@/api/wordpress";
// import ErrorPageNotFound from "@/components/ErrorPageNotFound";

export default {
  name: "SendungIdView",
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  beforeCreate() {
    let pbId = this.$route.params.sendungid;
    if (this.$route.path.includes('portfolio_page')) {
      let sendung = this.$route.params.sendungid;
      pbId = sendung.substr(0, sendung.indexOf('-'));
    }
    wpFetchSendungSlugByPluxboxId(pbId).then(slug => {
      if (slug !== false) {
        this.$router.replace('/sendung/' + slug)
      }
      else {
        this.$router.replace('/sendungen/')
      }
    });
  },
  created() {
  },

}
</script>

<style scoped>

</style>