<template>
  <svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path></svg>
</template>

<script>
export default {
  name: "SvgCarouselButtonPrevious"
}
</script>

<style scoped>

</style>