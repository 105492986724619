<template>
  <div class="content" :class="this.$route.name">
    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div v-if="programWeekdays">
          <div class="subnav">
            <router-link v-for="(day, d) in programWeekdays" :key="d" v-bind:to="day.date"
                         :class="[!this.$route.params.day  ? 'router-lsink-active':'']">{{ day.text }}
            </router-link>
          </div>
        </div>
      </Transition>

      <router-view :key="$route.path"></router-view>
    </div>
  </div>
</template>

<script>
import {dateGetWeekdays} from "@/api/date";
import {useProgress} from "@marcoschulte/vue3-progress";

export default {
  name: "ProgramView",
  data() {
    return {
      pageTitle: null,
      pageContent: null,
      programWeekdays: null
    }
  },
  async created() {
    this.$store.state.loadingProgressbarInit['program-days'] = useProgress().start();
    dateGetWeekdays().then(result => {
      this.programWeekdays = result
      this.pageTitle = 'Programm';
      this.$store.state.loadingProgressbarInit['program-days'].finish()
    })
  },
}
</script>

<style scoped>

</style>
