<template>
  <Transition name="post-background">
    <div class="post-background" v-if="postImage && !error404">
      <div class="background-image bga-fixed bg zi--1"
           v-bind:style="{ backgroundImage: 'url(' + postImage + ')' }">
      </div>
      <div class="background-gradient"></div>
    </div>
  </Transition>
  <div class="content">
    <ErrorPageNotFound v-if="error404" text="Dieser Beitrag existiert nicht"></ErrorPageNotFound>
    <div v-else class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="postTitle" v-html="postTitle"/>
      </Transition>
      <div class="row">
        <div class="order-1" :class="[postImage ? 'col-md-6':'col-md-10']">
          <Transition name="content">
            <div class="text" v-if="postContent" v-html="postContent"/>
          </Transition>
          <!-- Podcast -->
          <Transition name="content">
            <PartPlayerPodcastInline v-if="episodeFields.beitrag_episode_blubrry_url" :player-attrs="getPlayerAttrs"
                                     class="mt-1"/>
          </Transition>
                <Transition name="content">
            <div v-if="sendung_podcast_platforms" class="mt-1">
              <div class="row">
                <div class="" :class="[postImage ? 'col-sm-6 col-md-8':'col-sm-4 col-md-6']">
                  <h4 class="txt-aln-r">Podcast Abonnieren</h4>
                  <div class="subscribe" :class="[postImage ? '':'smaller']">
                    <div v-for="(platform, p) in sendung_podcast_platforms" :key="p" class="text ">
                      <a v-bind:href="platform.link" target="_blank" class="">
                        <i v-if="platform.faicon" :class="platform.faicon"></i>
                        <div v-else v-html="platform.name"></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <!-- Connection to Sendung/Podcast -->
          <Transition name="content">
            <div v-if="episodeFields.beitrag_episode_sendung_slug" class="text mt-2">
              <router-link v-bind:to="getEpisodeCategoryLink">Weitere Infos
              </router-link>
            </div>
          </Transition>
        </div>
        <div class="col-md-6 order-md-2 order-last">
          <Transition name="image">
            <div v-if="postImage" class="bg canvas can-square bga-scroll single-image mt-2 mt-md-0"
                 v-bind:style="{ backgroundImage: 'url(' + postImage + ')' }">
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {wpFetchPostData, wpFetchSendungData} from "@/api/wordpress";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import PartPlayerPodcastInline from "@/components/PartPlayerPodcastInline";
// import {globalGetUrlProtocol, globalIsInternalHref, globalRelativizeHref} from "@/main";

export default {
  name: "PostView",
  components: {PartPlayerPodcastInline, ErrorPageNotFound},
  data() {
    return {
      error404: false,
      postTitle: null,
      postContent: null,
      postContentRendered: null,
      postContentTest: null,
      postImage: null,
      episodeUrlCategories: [],
      episodeFields: {
        beitrag_episode_blubrry_url: false,
        beitrag_episode_sendung_title: false,
        beitrag_episode_sendung_slug: false,
      },
      sendung_podcast_platforms: false,
    }
  },
  computed: {
    getPlayerAttrs() {
      return {
        'playerMode': 'podcast',
        'podcastAudioUrl': this.episodeFields.beitrag_episode_blubrry_url,
        'podcastTitle': this.postTitle,
        'podcastSendungTitle': this.episodeFields.beitrag_episode_sendung_title,
        'podcastSendungSlug': this.episodeFields.beitrag_episode_sendung_slug,
        'podcastEpisode': this.$route.params.post,
        'podcastEpisodeRoutePath': this.$route.path,
        'podcastDuration': 0
      };
    },
    getEpisodeCategory() {
      if (this.$route.name.includes("sendungen")) {
        return '/sendung/';
      }
      if (this.$route.name.includes("podcasts")) {
        return '/podcast/';
      }
      if (this.episodeUrlCategories.includes('/sendung/')) {
        return '/sendung/';
      }
      if (this.episodeUrlCategories.includes('/podcast/')) {
        return '/podcast/';
      }
      return '/sendung/';
    },
    getEpisodeCategoryLink() {
      return this.getEpisodeCategory + this.episodeFields.beitrag_episode_sendung_slug;
    }
  },
  methods: {},
  watch: {},
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPostData(this.$route.params.post).then(post => {
      //check 404 & sendung should only display within this URL Parent
      //TODO
     /* var errorCheck = true;
      if(post.url_categories) {
        let urlCategories = post.url_categories;
        let urlCategoriesCount = urlCategories.length;
        while (urlCategoriesCount--) {
          if (this.$route.fullPath.indexOf(urlCategories[urlCategoriesCount]) != -1) {
            errorCheck = false;
          }
        }
      }
      if (post === false || !post.url_categories) {
        errorCheck = true;
      }
      if (errorCheck) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }*/
      if (post === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      //Fill in Content
      this.postTitle = post.title;
      this.postContent = post.content;
      this.postContentRendered = post.content_rendered;
      this.postContentTest = post.content_test;
      this.postImage = post.image;
      this.episodeUrlCategories = post.url_categories;
      this.episodeFields.beitrag_episode_blubrry_url = post.blubbry_podcast_url;
      this.episodeFields.beitrag_episode_podcast_platform = post.podcast_platform;
      this.episodeFields.beitrag_episode_sendung_title = post.episode_sendung_title;
      this.episodeFields.beitrag_episode_sendung_slug = post.episode_sendung_slug;
      if (this.episodeFields.beitrag_episode_sendung_slug) {
        wpFetchSendungData(this.episodeFields.beitrag_episode_sendung_slug).then(sendung => {
          this.sendung_podcast_platforms = sendung.podcast_platforms;
          this.$store.state.loadingProgressbar.finish()
        });
      } else {
        this.$store.state.loadingProgressbar.finish()
      }
    })
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>
