import axios from "axios";

let digiApiUrlBase = 'https://api.kanalk.ch/digi/';

/**
 * Current Song & Artist
 * @param wpPath
 * @returns {Promise<AxiosResponse<any>>}
 */
export function digiFetchCurrentSong() {
    return axios
        .get(digiApiUrlBase + '?q=current')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Last Played Twenty Songs (Song & Artist)
 * @returns {Promise<AxiosResponse<any>>}
 */
export function digiFetchPlaylist() {
    return axios
        .get(digiApiUrlBase + '?q=playlist')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}

/**
 * Played Song (&Artist) in given Time Period
 * @param dateTimeArray
 * @returns {Promise<AxiosResponse<any>>}
 */
export function digiFetchPlaylistFull(dateTimeStart, dateTimeEnd) {
    return axios
        .get(digiApiUrlBase + '?q=playlistFull&dateTimeStart=' + dateTimeStart.toISOString() + '&dateTimeEnd=' + dateTimeEnd.toISOString())
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        });
}