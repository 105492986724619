<template>
  <Transition name="post-background">
    <div class="post-background" v-if="postImage && !error404">
      <div class="background-image bga-fixed bg zi--1"
           v-bind:style="{ backgroundImage: 'url(' + postImage + ')' }">
      </div>
      <div class="background-gradient"></div>
    </div>
  </Transition>
  <div class="content">
    <ErrorPageNotFound text="Diese Sendung existiert nicht." v-if="error404"></ErrorPageNotFound>
    <div v-else class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="postTitle" v-html="postTitle"/>
      </Transition>
      <div class="row">
        <div class="order-1" :class="[postImage ? 'col-md-6':'col-md-10']">
          <TransitionGroup name="content">
            <div v-if="pbShowDescription" class="text" v-html="pbShowDescription"></div>
            <div v-if="postContent" class="mt-1 text" v-html="postContent"></div>
            <div v-if="pbShowModi" class="mt-1">
              <h4>Moderation / Redaktion</h4>
              <div class="text" v-html="pbShowModi"></div>
            </div>
            <div v-if="pbShowNext" class="mt-1">
              <h4>Nächste Sendungen</h4>
              <div class="text" v-for="(date,d) in pbShowNext" :key="d">
                {{ date }}
              </div>
            </div>
          </TransitionGroup>
        </div>
        <div class="col-md-6 order-md-2 order-last">
          <Transition name="image">
            <div v-if="postImage" class="bg canvas can-square bga-scroll single-image mt-2 mt-md-0"
                 v-bind:style="{ backgroundImage: 'url(' + postImage + ')' }">
            </div>
          </Transition>
        </div>
        <!-- Podcast -->
        <div class="col-12 order-3">
          <div v-if="sendungEpisodes || sendungFields.sendung_podcast_platforms" class="mt-1 "></div>
        </div>
        <div class="col-md-8 order-3">
          <Transition name="content">
            <div v-if="sendungEpisodes" class="">
              <h4>Episoden</h4>
              <ul v-for="(episode, e) in sendungEpisodes" :key="e" class="episodes text">
                <li>
                  <router-link v-bind:to="dashRoutePath + episode.slug" class="invert"
                               v-html="episode.title">
                  </router-link>
                </li>
              </ul>
            </div>
          </Transition>
        </div>
        <div class="col-sm-6 col-md-4 order-2 order-md-3">
          <Transition name="content">
            <div v-if="sendungFields.sendung_podcast_platforms" class="mt-1 mt-md-0">
              <h4 class="txt-aln-md-right">Abonnieren</h4>
              <div class="subscribe" :class="[sendungFields.sendung_podcast_platforms_count <= 3 ? 'right':'']">
                <div v-for="(platform, p) in sendungFields.sendung_podcast_platforms" :key="p" class="text ">
                  <a v-bind:href="platform.link" target="_blank" class="invert">
                    <i v-if="platform.faicon" :class="platform.faicon"></i>
                    <div v-else v-html="platform.name"/>
                  </a>
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <!-- Podcast End -->
      </div>
    </div>
  </div>
</template>

<script>
import {wpFetchPosts, wpFetchSendungData} from "@/api/wordpress";
import {pbFetchShowDescription, pbFetchShowModi, pbFetchShowNextBroadcasts} from "@/api/pluxbox";
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {globalGetUrlProtocol, globalIsInternalHref, globalRelativizeHref, globalDashPath} from "@/main";

export default {
  name: "SendungView",
  components: {ErrorPageNotFound},
  props: ['sendungid'],
  data() {
    return {
      error404: false,
      // wpPostData: null,
      postTitle: null,
      postContent: null,
      postImage: null,
      sendungFields: {
        sendung_pluxbox_id: null,
        sendung_soundcloud_playlist: false,
        sendung_blubrry_podcast_playlist: "",
        sendung_podcast_category: "",
        sendung_podcast_platforms: false,
        sendung_podcast_platforms_count: 0,
      },
      sendungEpisodes: false,
      pbShowDescription: '',
      pbShowModi: false,
      pbShowNext: false,
    }
  },
  computed: {
    dashRoutePath(){
      return globalDashPath(this.$route.path);
    }
  },
  methods: {
    loadData() {
    }
  },
  watch: {},
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchSendungData(this.$route.params.sendung).then(sendung => {
      //check 404 & sendung should only display within this URL Parent
      //TODO evtl.
     /* var errorCheck = true;
      if(sendung.type) {
        let urlCategories = sendung.type;
        let urlCategoriesCount = urlCategories.length;
        while (urlCategoriesCount--) {
          if (this.$route.fullPath.indexOf(urlCategories[urlCategoriesCount]) != -1) {
            errorCheck = false;
          }
        }
      }
      if (sendung === false || !sendung.type) {
        errorCheck = true;
      }
     if (errorCheck) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }*/
      if (sendung === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      //fill in content*/
      this.postTitle = sendung.title;
      this.postContent = sendung.content;
      this.postImage = sendung.image;
      this.sendungFields.sendung_pluxbox_id = sendung.pluxbox_id;
      this.sendungFields.sendung_soundcloud_playlist = sendung.soundcloud_playlist;
      this.sendungFields.sendung_podcast_category = sendung.podcast_category;
      this.sendungFields.sendung_blubrry_podcast_playlist = sendung.blubrry_podcast_playlist;
      this.sendungFields.sendung_podcast_platforms = sendung.podcast_platforms;
      this.sendungFields.sendung_podcast_platforms_count = sendung.podcast_platforms_count;
      pbFetchShowDescription(this.sendungFields.sendung_pluxbox_id).then(result => {
        this.pbShowDescription = result;
      });
      pbFetchShowModi(this.sendungFields.sendung_pluxbox_id).then(result => {
        this.pbShowModi = result;
      });
      pbFetchShowNextBroadcasts(this.sendungFields.sendung_pluxbox_id).then(result => {
        this.pbShowNext = result
      });
      wpFetchPosts(this.sendungFields.sendung_podcast_category, -1).then(result => {
        this.sendungEpisodes = result.posts
      });
      this.$store.state.loadingProgressbar.finish()
    })
  },
  mounted() {
    //Replace Standard Anchors with Vue Router
    window.addEventListener('click', event => {
      let target = event.target;
      if (target.tagName == 'A') { //only A Tags
        if (target && target.href && globalIsInternalHref(target.href)) { //internal Link
          event.preventDefault();
          this.$router.push(globalRelativizeHref(target.href));
        } else { //external Link (incl. Downloads)
          if (globalGetUrlProtocol(target.href) != 'mailto:') {
            event.preventDefault();
            window.open(target.href, '_blank');
          }
        }
      }
    });
  },
}
</script>

<style scoped>
ul.episodes {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.episodes > li {
  margin-bottom: 0.6rem;
}
</style>
