<template>
  <div class="content">
    <ErrorPageNotFound text="" v-if="error404" ></ErrorPageNotFound>
    <div class="container-fluid container-inner container-max">
      <Transition name="title">
        <h2 v-if="pageTitle" v-html="pageTitle"/>
      </Transition>
      <Transition name="content">
        <div v-if="pageContent" class="mb-1" v-html="pageContent"/>
      </Transition>
      <TransitionGroup name="content">
        <template v-for="(link, l) in helloLinks" :key="l">
        <a v-if="link.link_type == 'extern'" class="hello-link" v-bind:href="link.link" target="_blank" v-html="link.title"/>
          <router-link v-else-if="link.link_type == 'intern'" v-bind:to="link.link" class="hello-link" v-html="link.title"/>
        </template>
      </TransitionGroup>
      </div>
  </div>
</template>

<script>
import ErrorPageNotFound from "@/components/ErrorPageNotFound";
import {wpFetchPageData, wpFetchHelloPosts} from "@/api/wordpress";

export default {
  name: "HelloView",
  components: {ErrorPageNotFound},
  data() {
    return {
      error404: false,
      pageTitle: null,
      pageContent: null,
      helloLinks: null
    }
  },
  methods: {},
  computed: {},
  async created() {
    if(this.$progress.state().active) { //Reset Loading Progressbar
      this.$store.state.loadingProgressbar = this.$progress.start();
      this.$store.state.loadingProgressbar.finish()
    }
    this.$store.state.loadingProgressbar = this.$progress.start();
    wpFetchPageData(this.$route.path).then(result => {
      //check 404
      if(result === false) {
        this.error404 = true;
        this.$store.state.loadingProgressbar.finish()
        return false;
      }
      this.pageTitle = result.title;
      this.pageContent = result.content;
      this.pageContent = '';
      wpFetchHelloPosts().then(result => {
        this.helloLinks = result;
        this.$store.state.loadingProgressbar.finish()
      })
    })
  },
  mounted() {
  },
  watch: {},
}
</script>

<style scoped>
.hello-link {
  /*background-color: var(--color-random2);*/
  border: solid var(--border-width-bold) var(--color-random2);
  padding: 10px 15px;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--color-random2);
  display: block;
  text-decoration: none !important;
  transition: all ease var(--transition-time2);
}

.hello-link:hover {
  border-color: var(--color-random1);
  color: var(--color-random1);
  background-color: var(--color-random2)
}
.hello-link:last-of-type {
  margin-bottom: 0;
}
</style>
