import {createRouter, createWebHistory} from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import KommunityPeopleView from "@/views/KommunityPeopleView";
import SendungenView from "@/views/SendungenView";
import SendungView from "@/views/SendungView";
import PostCategoryView from "@/views/PostCategoryView";
import PostView from "@/views/PostView";
import ErrorView from "@/views/ErrorView";
import SendungIdView from "@/views/SendungIdView";
import PlayerView from "@/views/PlayerView";
import ProgramView from "@/views/ProgramView";
import ProgrammDayView from "@/views/ProgrammDayView";
import TrackserviceView from "@/views/TrackserviceView";
import BackendView from "@/views/BackendView";
import PageView from "@/views/PageView";
import PageSubpagesView from "@/views/PageSubpagesView";
import HelloView from "@/views/HelloView";


const routes = [
    //Landing
    {path: '/', name: 'landing', component: LandingView},

    //Main Pages
    {path: '/ausbildung', name: 'ausbildung', component: PageView},
    {path: '/ausbildung/radiokurse', name: 'ausbildung radiokurse', component: PageSubpagesView},
    {
        path: '/ausbildung/radiokurse/:subpage', redirect: to => {
            return {path: '/ausbildung/radiokurse', hash: '#' + to.params.subpage}
        },
    },
    {path: '/ausbildung/schulen', name: 'ausbildung schulen', component: PageSubpagesView},
    {
        path: '/ausbildung/schulen/:subpage', redirect: to => {
            return {path: '/ausbildung/schulen', hash: '#' + to.params.subpage}
        },
    },
    {path: '/ausbildung/:subpage', name: 'ausbildung subpage', component: PageView},

    {path: '/kommunity', name: 'kommunity', component: PageView},
    {path: '/kommunity/:people', name: 'kommunity people', component: KommunityPeopleView},
    {path: '/kommunity/mitglied', name: 'kommunity mitglied', component: PageView, props: {showMemberForm: true}},
    {path: '/member', redirect: '/kommunity/mitglied'},
    {path: '/mitglied', redirect: '/kommunity/mitglied'},
    {path: '/mitglied', name: '', redirect: '/kommunity/mitglied'},
    {path: '/kommunity/selber-radiomachen', name: 'kommunity mitmachen', component: PageView},
    {path: '/kommunity/selber-radiomachen-und-podcasten', name: 'kommunity mitmachen podcasten', component: PageView},

    // Selber Radiomachen und Podcasten
    // {path: '/kommunity/radioprojekte', name: 'kommunity radioprojekte', component: PageView},
    {path: '/kommunity/radioprojekte', name: 'kommunity radioprojekte', component: PageSubpagesView},
    {
        path: '/kommunity/radioprojekte/:subpage', redirect: to => {
            return {path: '/kommunity/radioprojekte', hash: '#' + to.params.subpage}
        },
    },

    {path: '/musik/', name: 'musik mainpage', component: PageView},
    {path: '/musik/einsendungen', name: 'musik einsendungen', component: PageView},

    {path: '/musik/:postCat', name: 'musik category', component: PostCategoryView, props: true},
    {path: '/musik/:postCat/:post', name: 'musik category beitrag post-background', component: PostView},

    {path: '/sendungen/', name: 'sendungen', component: SendungenView, props: {showSearchResults: true, sendungType: 'sendung'}},
    {path: '/sendungen/suche', name: 'sendungen search', component: SendungenView, props: {showSearchResults: false, sendungType: 'sendung'}},
    // {path: '/podcasts/aktuelle-episoden', name: 'sendungen aktuell', component: PostCategoryView, props: {postCat: 'sendungen', postCatSlug: 'sendung'}},
    {path: '/sendung/:sendung', name: 'sendungen subpage post-background', component: SendungView},
    {path: '/sendung/:sendungid(\\d+)', name: 'sendungen id', component: SendungIdView},
    {path: '/sendung/:sendungname/:post', name: 'sendungen episode post-background', component: PostView},
    {path: '/portfolio_page/:sendungid', name: 'sendungen id old', component: SendungIdView}, //alte Schreibweise Portfolio / Sendungen
    {path: '/portfolio_page/:sendungid/:name', name: 'sendungen id old two', component: SendungIdView}, //alte Schreibweise Portfolio / Sendungen

    // {path: '/podcasts/', name: 'podcasts', component: SendungenView, props: {postCat: 'podcasts'}},
    {path: '/podcasts/', name: 'podcasts', component: SendungenView, props: {showSearchResults: true, sendungType: 'podcast'}},
    {path: '/podcasts/suche', name: 'podcasts search', component: SendungenView, props: {showSearchResults: false, sendungType: 'podcast'}},
    {path: '/podcasts/episoden', name: 'podcasts aktuell', component: PostCategoryView, props: {postCat: 'podcasts', postCatSlug: 'podcast'}},
    {path: '/podcast/:sendung', name: 'podcasts subpage post-background', component: SendungView},
    {path: '/podcast/:sendungname/:post', name: 'podcasts episode post-background', component: PostView},
    {path: '/podcast-episode/:post', name: 'podcasts categoryless episode post-background', component: PostView}, //Single Standalone Episode Podcast
    {path: '/sendung-episide/:post', name: 'sendungen categoryless episode post-background', component: PostView}, //Single Standalone Episode Sendung
    {path: '/nachhoeren/:post', name: 'podcasts categoryless episode post-background', component: PostView}, //Single Standalone Episode

    //POSTS
    {path: '/news', name: 'post category news', component: PostCategoryView, props: {postCat: 'news', postCatSlug: 'news'}},
    {path: '/news/:post', name: 'post news post-background', component: PostView},

    {path: '/:y(\\d+)/:m(\\d+)/:d(\\d+)/:post', name: 'post old post-background', component: PostView}, //Alte Schreibweise: Post mit Datum

    //Info Pages (Secondary Menues):
    {path: '/:page', name: 'infos page all', component: PageView},

    //Weitere Seiten
    // {path: '/podcasts', name: 'podcasts page', component: PostCategoryView, props: {postCat: 'podcasts'}},
    {path: '/player', name: 'player', component: PlayerView},
    {path: '/hello', name: 'hello', component: HelloView},
    {
        path: '/programm', name: 'page program', alias: '/sendungen/programm', component: ProgramView, children:
            [
                {path: '', name: 'page program redirect', redirect: '/programm/today'},
                // {path: 'today', name: 'sendungen program today', component: ProgrammDayView,},
                {path: ':day', name: 'page program day', component: ProgrammDayView,},
            ],
    },
    {path: '/wochenprogramm', name: 'sendungen wochenprogramm', redirect: '/programm'},
    {path: '/trackservice', name: 'page trackservice', component: TrackserviceView},
    {path: '/newsletter', name: 'page newsletter', component: PageView, props: {showNewsletterForm: true}},
    {path: '/cookies', name: 'page cookies', component: PageView, props: {showCookieSettings: true}},

    //Backend Access /wp-admin
    {path: '/wp-admin', name: 'backend wp-admin page', alias: '/wp-admin/index.php', component: BackendView},
    {path: '/wp-login', name: 'backend wp-login page', alias: '/wp-login/index.php', component: BackendView},

    //Error
    {path: '/:pathMatch(.*)*', name: 'page 404', component: ErrorView},
    //Temp Error DEV
    {path: '/cookies', name: 'page 404 cookies', component: ErrorView},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        /*Scroll To Saved Position*/
        if (savedPosition) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        top: savedPosition.top,
                        behavior: 'smooth',
                    })
                }, 400)
            })
        }
        /*Scroll To Hash*/
        if (to.hash) {
            return {
                el: to.hash,
                // selector: to.hash,
                behavior: 'smooth',
                // top: 180,
                top: 250
            }
        }
        /*Scroll To Top (Default)*/
        return {
            top: 0,
            behavior: 'smooth',
        }
    },
})
export default router
