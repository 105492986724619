<template>
  <PartPlayerLanding></PartPlayerLanding>
  <div class="content">
    <!-- Section News Slider -->
    <SectionPostSlider section-id="news" section-class="" posts-category="news-slider" post-link-text="weiterlesen"
                       section-title="News" post-cat="news" post-cat-slug="news" button-link="/news" button-text="Alle News"
                       store-cache="landingNews" min-posts="3" max-posts="6"></SectionPostSlider>
    <!-- Section Nachhören Slider -->
    <SectionPostSlider section-id="podcasts" section-class="" posts-category="podcast-slider" post-link-text="hören"
                       section-title="Podcasts" post-cat="podcasts" post-cat-slug="podcast" button-link="/podcasts/episoden/"
                       button-text="Mehr Podcast-Episoden" min-posts="3" max-posts="600" show-sendung></SectionPostSlider>
    <!-- Section Newsletter -->
    <SectionNewsletter section-class=""></SectionNewsletter>
  </div>
</template>
<script>
// @ is an alias to /src
import SectionNewsletter from "@/components/SectionNewsletter";
import PartPlayerLanding from "@/components/PartPlayerLanding";
import SectionPostSlider from "@/components/SectionPostSlider";


export default {
  name: 'LandingView',
  components: {
    SectionPostSlider,
    PartPlayerLanding,
    SectionNewsletter,
  },
  data() {
    return { }
  },
  methods: {
    detectIsScrolledLanding() {
      let isScrolledThreshold = window.innerHeight * 0.8
      if (window.scrollY > isScrolledThreshold) {
        this.$store.commit('setPlayerDisplay', 'default')
        this.$store.commit('closeBoxPlaylistLanding')
        this.$store.commit('closeBoxProgramLanding')
      } else {
        this.$store.commit('setPlayerDisplay', 'full')
      }
    },
  },
  created() {
    /* Is Scrolled Landing Detection */
    window.addEventListener('scroll', this.detectIsScrolledLanding);
    this.detectIsScrolledLanding();
  }
}
</script>
<style scoped>
section {
  padding: 0;
}
</style>
