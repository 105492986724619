<template>
  <div class="program-playlist" :class="[wrapperClass]">
    <!-- Programm Info Box -->
    <div id="programm-box" class="box program" :class="this.$store.getters.getBoxProgramClass">
      <div class="header" @click.prevent="this.$store.dispatch('toggleBoxProgram')">
        <div class="arrow">
          <div>
            <SvgArrow></SvgArrow>
          </div>
        </div>
        <div class="title">Programm</div>
      </div>
      <div class="expand-list">
        <div v-for="(prg, i) in this.$store.getters.getRadiotxtProgramToday" :key="i" class="prg-wrapper">
          <div class="prg-time">{{ prg.start }} - {{ prg.stop }}</div>
          <div class="prg-title">
            <router-link v-bind:to="'/sendung/' + prg.programId"
                         @click.prevent="this.$store.commit('closeBoxProgram')">
              {{ prg.title }}
            </router-link>
          </div>
        </div>
        <div class="prg-wrapper more">
          <div class="prg-title">
            <router-link @click.prevent="this.$store.commit('closeBoxAll')" to="/programm">Wochenprogramm</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Playlist Info Box -->
    <div id="playlist-box" class="box playlist" :class="this.$store.getters.getBoxPlaylistClass">
      <div class="header" @click.prevent="this.$store.dispatch('toggleBoxPlaylist')">
        <div class="arrow">
          <SvgArrow></SvgArrow>
        </div>
        <div class="title">Abgespielt</div>
      </div>

      <div class="expand-list">
        <div v-for="(prg, i) in this.$store.getters.getRadiotxtPlaylist" :key="i" class="prg-wrapper">
          <div class="prg-time">{{ prg.date }} - {{ prg.time }}</div>
          <div class="prg-title">
            {{ prg.artist }} - {{ prg.title }}
          </div>
        </div>
        <div class="prg-wrapper more">
          <div class="prg-title">
            <router-link @click.prevent="this.$store.commit('closeBoxAll')" class="" to="/trackservice">
              Trackservice
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgArrow from "@/components/svg/SvgArrow";

export default {
  name: "BoxProgrammPlaylist",
  components: {
    SvgArrow
  },
  props: {
    wrapperClass: String
  },
  methods: {},
}
</script>

<style scoped>
.program-playlist .box {
  display: block;
  top: 50%;
  left: initial;
  right: 0;
}

.program-playlist .box.expanded {
  z-index: 7;
}

.program-playlist .box.program {
  transform: translate(100%, -100%);
  border-bottom: none;
}

.program-playlist .box.playlist {
  transform: translate(100%, 0%);
}

.program-playlist .box > .header {
  display: flex;
}

.program-playlist .box > .header > .title {
  padding: 0;
  margin-left: 0.5rem;
  writing-mode: initial;
  transform: rotate(0deg);
  min-width: 150px;
}

.program-playlist .box > .header > .arrow {
  order: 2;
  border-bottom: none;
}

.program-playlist .box > .expand-list {
  position: relative;
  z-index: 7;
  width: 300px;
  max-width: inherit;
  left: calc(var(--border-width-thin) * -1);
  top: 30px;
  position: absolute;
  height: 300px;
  z-index: 18;
  background-color: var(--color-random2);
  transition: transform ease var(--transition-time1);
  transform: scaleY(0);
  transform-origin: top;
  border: var(--border-width-thin) var(--color-black) solid;
  padding-bottom: 0.3rem;
}

.program-playlist .box.expanded > .expand-list {
  transform: scaleY(1);
}

@media (max-width: 992px) {
  .program-playlist {
    transform: translate(-50%, calc(var(--song-marquee-height) - 1px));
  }

  .program-playlist .box.program {
    border-right-width: 0.5px;
    transform: translate(0);
    border-bottom: solid var(--color-black) var(--border-width-thin);
  }

  .program-playlist .box > .expand-list {
    width: 100vw;
  }

  .program-playlist .box.playlist > .expand-list {
    transform: scaleY(0) translateX(-50%);
  }

  .program-playlist .box.expanded.playlist > .expand-list {
    transform: scaleY(1) translateX(-50%);
  }

  /*  .program-playlist {
      width: 100%;
      position: absolute;
      bottom: 0;
      transform: translate(-50%,calc(var(--song-marquee-height) - 1px));
    }
    .program-playlist .box {
      width: 50vw;
    }
    .program-playlist .box.playlist {
      border-left-width: 0.5px;
    }
    */
  /*.program-playlist .box.program {
    border-right-width: 0.5px;
    transform: translate(0);
    border-bottom: solid var(--color-black) var(--border-width-thin);
  }
  .program-playlist .box > .header {
    justify-content: space-between;
  }
  .program-playlist .box > .header > .arrow {
    margin-right: 0.5rem;
  }
  .program-playlist .box > .expand-list {
    width: 100vw;
  }
  .program-playlist .box.playlist > .expand-list {
    transform: scaleY(0) translateX(-50%);
  }
  .program-playlist .box.expanded.playlist > .expand-list {
    transform: scaleY(1) translateX(-50%);
  }*/
}
</style>
