import axios from "axios";

let dateApiUrlBase = 'https://api.kanalk.ch/date/';

/**
 * Weekdays inkl. Text for one Week from today
 * @returns {Promise<AxiosResponse<any>>}
 */
export function dateGetWeekdays() {
    return axios
        .get(dateApiUrlBase + '?q=weekdays')
        .then(res => {
            if (typeof res.data !== "undefined") {
                return res.data
            }
            return false;
        })
}